import configConstants from "./constants/config";
import pagesConstants from "../../constants/pages";
import eventsConstants from "../../constants/events";
import layoutsConstants from "../../constants/layouts";
import actionsConstants from "../../constants/actions";
import productsConstants from "../../constants/products";
import statusPlansConstants from "../../constants/statusPlans";
import subscriptionsTypesConstants from "../../constants/subscriptionsTypes";
import businessRulesConstants from "../../constants/businessRules";
import issuesStatusConstants from "../../constants/issuesStatus";
import translateConstants from "../../langs/us";
import formatsConstants from "./constants/formats";
import typeFormatsConstants from "./constants/typeFormats";
import auditLogTransactions from "./constants/auditLogTransactions";
import healthCheck from "./constants/healthCheck";
import incorporations from "./constants/incorporations";
import companyTypes from "./constants/companyTypes";
import questionnaireKeys from "./constants/questionnaireKeys";
import portalDocumentsKeys from "./constants/portalDocumentsKeys"
import entityTypes from "./constants/entityTypes";
import regexp from "./constants/regexp";
import formsTypes from './constants/forms'
import labelFieldsFormGenerate from './constants/generateLabelsFields'
import logger from "./constants/logger"
import modules from "./../../constants"
const NodeTypes = {
  USER: "User",
  DOCUMENT: "Document",
  STAKEHOLDER: "Stakeholder",
  OWNER: "Owner",
  STARTUP: "Startup",
  AGREEMENT: "Agreement"
};

//TODO bussines rules should be moved to api

/*
DOCUMENT_TYPE_IDENTITY_DOCUMENTS:
-Should only be individual stakeholder and only one
*/

const DOCUMENT_TYPE_IDENTITY_DOCUMENTS = [
  "PROOF_OF_ADDRESS_DOCUMENT",
  "THIRDPARTY_ADDRESS_FORM_DOCUMENT",
  "THIRDPARTY_ADDRESS_PASSPORT_DOCUMENT",
  "UAE_VISA_DOCUMENT",
  "UAE_VISA_LASTPAGE_DOCUMENT",
  "PASSPORT_DOCUMENT",
  "EID_DOCUMENT",
  "EID_LASTPAGE_DOCUMENT",
  "UID_STAMP_DOCUMENT",
  "OLDPASSPORT_DOCUMENT",
  "GCC_RESIDENT_ID_DOCUMENT",
  "NATIONAL_ID_DOCUMENT",
  "UAE_RESIDENCE_VISA_DOCUMENT",
  "UAE_ENTRY_STAMP_DOCUMENT",
  "ADDRESS_FAMILY_CONTACT_PAGE_DOCUMENT"
]
//Identity documents that aren't PASSPORT_DOCUMENT_TYPES and are not VISA_STAMPS_RESIDENCE_ID_DOCUMENT_TYPES don't need additional info 
//Require Exp date, nationality and passport number
const PASSPORT_DOCUMENT_TYPES =[
  "THIRDPARTY_ADDRESS_PASSPORT_DOCUMENT",
  "PASSPORT_DOCUMENT",
  "OLDPASSPORT_DOCUMENT",
];
//Require only Exp date 
const VISA_STAMPS_RESIDENCE_ID_DOCUMENTS_TYPES = [
  "UAE_VISA_DOCUMENT",
  "UAE_VISA_LASTPAGE_DOCUMENT",
  "GCC_RESIDENT_ID_DOCUMENT",
  "NATIONAL_ID_DOCUMENT",
  "UAE_RESIDENCE_VISA_DOCUMENT",
  "EID_DOCUMENT",
  "EID_LASTPAGE_DOCUMENT",
]

//File extensions block to upload
const BLOCK_EXTENSIONS = [
  "heic",
  "HEIC",
]

const COUNTRYCODES = {
  Afghanistan: "AF",
  "Antillas Holandesas": "ANT",
  Albania: "AL",
  Algeria: "DZ",
  "American Samoa": "AS",
  Andorra: "AD",
  Angola: "AO",
  Anguilla: "AI",
  "Antigua and Barbuda": "AG",
  Argentina: "AR",
  Armenia: "AM",
  Aruba: "AW",
  Australia: "AU",
  Austria: "AT",
  Azerbaijan: "AZ",
  Bahamas: "BS",
  Bahrain: "BH",
  Bangladesh: "BD",
  Barbados: "BB",
  Belarus: "BY",
  Belgium: "BE",
  Belize: "BZ",
  Benin: "BJ",
  Bermuda: "BM",
  Bhutan: "BT",
  Bolivia: "BO",
  "Bosnia and Herzegovina": "BA",
  Botswana: "BW",
  Brazil: "BR",
  "British Indian Ocean Territory": "IO",
  Brunei: "BN",
  Bulgaria: "BG",
  "Burkina Faso": "BF",
  Burundi: "BI",
  Cambodia: "KH",
  Cameroon: "CM",
  Canada: "CA",
  "Cape Verde": "CV",
  "Cayman Islands": "KY",
  Chad: "TD",
  Chile: "CL",
  China: "CN",
  Colombia: "CO",
  Congo: "CG",
  "Cook Islands": "CK",
  "Costa Rica": "CR",
  Croatia: "HR",
  Cuba: "CU",
  Cyprus: "CY",
  "Czech Republic": "CZ",
  Denmark: "DK",
  Djibouti: "DJ",
  Dominica: "DM",
  "Dominican Republic": "DO",
  Ecuador: "EC",
  Egypt: "EG",
  "El Salvador": "SV",
  "Equatorial Guinea": "GQ",
  Estonia: "EE",
  Ethiopia: "ET",
  "Falkland Islands": "FK",
  "Faroe Islands": "FO",
  Fiji: "FJ",
  Finland: "FI",
  France: "FR",
  "French Polynesia": "PF",
  Gabon: "GA",
  Gambia: "GM",
  Georgia: "GE",
  Germany: "DE",
  Ghana: "GH",
  Gibraltar: "GI",
  Greece: "GR",
  Greenland: "GL",
  Grenada: "GD",
  Guam: "GU",
  Guatemala: "GT",
  Guernsey: "GG",
  Guinea: "GN",
  "Guinea Bissau": "GW",
  Guyana: "GY",
  Haiti: "HT",
  Honduras: "HN",
  "Hong Kong": "HK",
  Hungary: "HU",
  Iceland: "IS",
  India: "IN",
  Indonesia: "ID",
  Iran: "IR",
  Iraq: "IQ",
  Ireland: "IE",
  "Isle of Man": "IM",
  Israel: "IL",
  Italy: "IT",
  Jamaica: "JM",
  Japan: "JP",
  Jersey: "JE",
  Jordan: "JO",
  Kazakhstan: "KZ",
  Kenya: "KE",
  Kuwait: "KW",
  "Kyrgyz Republic": "KG",
  Laos: "LA",
  Latvia: "LV",
  Lebanon: "LB",
  Lesotho: "LS",
  Liberia: "LR",
  Libya: "LY",
  Liechtenstein: "LI",
  Lithuania: "LT",
  Luxembourg: "LU",
  Macau: "MO",
  Madagascar: "MG",
  Malawi: "MW",
  Malaysia: "MY",
  Maldives: "MV",
  Mali: "ML",
  Malta: "MT",
  Mauritania: "MR",
  Mauritius: "MU",
  Mexico: "MX",
  Moldova: "MD",
  Monaco: "MC",
  Mongolia: "MN",
  Montenegro: "ME",
  Montserrat: "MS",
  Morocco: "MA",
  Mozambique: "MZ",
  Namibia: "NA",
  Nepal: "NP",
  Netherlands: "NL",
  "New Caledonia": "NC",
  "New Zealand": "NZ",
  Nicaragua: "NI",
  Niger: "NE",
  Nigeria: "NG",
  NU: "Niue",
  Norway: "NO",
  Oman: "OM",
  Pakistan: "PK",
  Palestine: "PS",
  Panama: "PA",
  "Papua New Guinea": "PG",
  Paraguay: "PY",
  Peru: "PE",
  Philippines: "PH",
  Poland: "PL",
  Portugal: "PT",
  "Puerto Rico": "PR",
  Qatar: "QA",
  Romania: "RO",
  Russia: "RU",
  Rwanda: "RW",
  Reunion: "RE",
  "Saint Pierre and Miquelon": "PM",
  Samoa: "WS",
  "San Marino": "SM",
  "Saudi Arabia": "SA",
  Senegal: "SN",
  Serbia: "RS",
  Seychelles: "SC",
  "Sierra Leone": "SL",
  Singapore: "SG",
  Slovakia: "SK",
  Slovenia: "SI",
  "South Africa": "ZA",
  Spain: "ES",
  "Sri Lanka": "LK",
  Sudan: "SD",
  Suriname: "SR",
  Sweden: "SE",
  Switzerland: "CH",
  Syria: "SY",
  Taiwan: "TW",
  Tajikistan: "TJ",
  Tanzania: "TZ",
  Thailand: "TH",
  "Timor L'Este": "TL",
  Togo: "TG",
  Tonga: "TO",
  "Trinidad and Tobago": "TT",
  Tunisia: "TN",
  Turkey: "TR",
  Turkmenistan: "TM",
  Uganda: "UG",
  Ukraine: "UA",
  "United Arab Emirates": "AE",
  "United Kingdom": "GB",
  "United States Minor Outlying Islands": "UM",
  "United States": "US",
  Uruguay: "UY",
  Uzbekistan: "UZ",
  Venezuela: "VE",
  Vietnam: "VN",
  "Virgin Islands (US)": "VI",
  Yemen: "YE",
  Zambia: "ZM",
  Zimbabwe: "ZW"
};
const COUNTRIES = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Congo",
  "Cook Islands",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Polynesia",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kuwait",
  "Kyrgyz Republic",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macau",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Namibia",
  "Nepal",
  "Netherlands",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Norway",
  "Oman",
  "Pakistan",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Reunion",
  "Saint Pierre and Miquelon",
  "Samoa",
  "San Marino",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "South Africa",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor L'Este",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "United States Minor Outlying Islands",
  "Uruguay",
  "Uzbekistan",
  "Venezuela",
  "Vietnam",
  "Virgin Islands (US)",
  "Yemen",
  "Zambia",
  "Zimbabwe"
];
const CURRENCIES = [
  "AFN",
  "EUR",
  "ALL",
  "DZD",
  "USD",
  "AOA",
  "XCD",
  "ARS",
  "AMD",
  "AWG",
  "AUD",
  "AZN",
  "BSD",
  "BHD",
  "BDT",
  "BBD",
  "BYN",
  "BZD",
  "XOF",
  "BMD",
  "INR",
  "BTN",
  "BOB",
  "BOV",
  "BAM",
  "BWP",
  "NOK",
  "BRL",
  "BND",
  "BGN",
  "BIF",
  "CVE",
  "KHR",
  "XAF",
  "CAD",
  "KYD",
  "CLP",
  "CLF",
  "CNY",
  "COP",
  "COU",
  "KMF",
  "CDF",
  "NZD",
  "CRC",
  "HRK",
  "CUP",
  "CUC",
  "ANG",
  "CZK",
  "DKK",
  "DJF",
  "DOP",
  "EGP",
  "SVC",
  "ERN",
  "ETB",
  "FKP",
  "FJD",
  "XPF",
  "GMD",
  "GEL",
  "GHS",
  "GIP",
  "GTQ",
  "GBP",
  "GNF",
  "GYD",
  "HTG",
  "HNL",
  "HKD",
  "HUF",
  "ISK",
  "IDR",
  "XDR",
  "IRR",
  "IQD",
  "ILS",
  "JMD",
  "JPY",
  "JOD",
  "KZT",
  "KES",
  "KPW",
  "KRW",
  "KWD",
  "KGS",
  "LAK",
  "LBP",
  "LSL",
  "ZAR",
  "LRD",
  "LYD",
  "CHF",
  "MOP",
  "MKD",
  "MGA",
  "MWK",
  "MYR",
  "MVR",
  "MRU",
  "MUR",
  "XUA",
  "MXN",
  "MXV",
  "MDL",
  "MNT",
  "MAD",
  "MZN",
  "MMK",
  "NAD",
  "NPR",
  "NIO",
  "NGN",
  "OMR",
  "PKR",
  "PAB",
  "PGK",
  "PYG",
  "PEN",
  "PHP",
  "PLN",
  "QAR",
  "RON",
  "RUB",
  "RWF",
  "SHP",
  "WST",
  "STN",
  "SAR",
  "RSD",
  "SCR",
  "SLL",
  "SGD",
  "XSU",
  "SBD",
  "SOS",
  "SSP",
  "LKR",
  "SDG",
  "SRD",
  "SZL",
  "SEK",
  "CHE",
  "CHW",
  "SYP",
  "TWD",
  "TJS",
  "TZS",
  "THB",
  "TOP",
  "TTD",
  "TND",
  "TRY",
  "TMT",
  "UGX",
  "UAH",
  "AED",
  "USN",
  "UYU",
  "UYI",
  "UYW",
  "UZS",
  "VUV",
  "VES",
  "VND",
  "YER",
  "ZMW",
  "ZWL",
  "XBA",
  "XBB",
  "XBC",
  "XBD",
  "XTS",
  "XXX",
  "XAU",
  "XPD",
  "XPT",
  "XAG"
];

const CHAT_COMPONENTS_TYPE = {
  TEXT: "TEXT",
  FILE: "FILE_UPLOAD",
  PASSWORD: "PASSWORD",
  VIDEO: "VIDEO",
  OPTIONS: "OPTIONS",
  RADIOBUTTON: "RADIOBUTTON",
  SUMMARY: "SUMMARY",
  AUTOMATIC: "AUTOMATIC",
  LEARN_MORE: "LEARN_MORE",
  STOP_FLOW: "STOP_FLOW"
};

const BACKGROUND_TYPE = {
  WARNING: "WARNING",
  DANGER: "DANGER"
};

const Urls = {
  FOLDER_API: "/api"
};

const AGREEMENT_STATUS = {
  // MISSING: "MISSING",
  // SIGNED: "SIGNED",
  // EXPIRED: "EXPIRED",
  DRAFT: "Draft",
  PENDING: "Pending",
  SIGNED: "Signed",
  UNSIGNED: "Unsigned",
  VALID: "Valid",
  EXPIRED: "Expired",
  UPLOADED: "Uploaded"
};
const FORMS = {
  STARTUP: "startup",
  GROUP_COMPANIES: "groupCompanies",
  STAKEHODLER: "stakeholder",
  STAKEHOLDER_COMPANY: "stakeholder_company",
  STAKEHODLER_INDIVIDUAL: "stakeholder_individual",
  AGREEMENT: "agreement",
  DOCUMENT_DRAFT: "documentDraft",
  GEN_AGREEMENT: "generateAgreement",
  DUE_DILIGENCE: "dueDiligence",
  EDIT_GEN_AGREEMENT: "editGenerateAgreement",
  EQUITY_POSITION: "equityPosition",
  DOCUMENT: {
    UPLOAD: "UPLOAD_DOCUMENT",
    GENERATE: "GENERATE_DOCUMENT"
  },
  EQUITY: "equity"
};
const FORMS_MODE = {
  EDIT: "edit",
  ADD: "add",
  VIEW: "view",
  GENERATE: "generate",
  UPLOAD: "upload",
  DELETE: "delete"
};
const ROLES = {
  FOUNDER: "founder",
  GROUP_COMPANIES: "groupCompany",
  EMPLOYEE: "employee",
  CONSULTANT: "consultant",
  INVESTOR: "investor",
  ADVISOR: "advisor",
  SHARE_HOLDER: "shareholder",
  CONVERTIBLE_HOLDER: "convertibleholder",
  WARRANT_HOLDER: "warrantholder",
  OPTIONS_HOLDER: "optionholder",
  DIRECTOR: "director",
  IP_ASSIGNOR: "ipassignor",
  THIRD_PARTY: "thirdparties"
};
const ROLES_LABEL = {
  FOUNDER: "Founder",
  ADVISOR: "Advisor",
  EMPLOYEE: "Employee",
  CONSULTANT: "Consultant",
  INVESTOR: "Investor",
  OPTIONHOLDER: "Option Holder",
  CONVERTIBLEHOLDER: "Convertible Holder",
  WARRANTHOLDER: "Warrant Holder",
  SHAREHOLDER: "Shareholder",
  IPASSIGNOR: "IP Assignor",
  THIRDPARTY: "Third Party",
  DIRECTOR: "Director",
  AUTHORIZEDSIGNATORY: "Authorised Signatory",
  UBO: "Beneficial Owner"
};

const ROLES_CODE = {
  FOUNDER: "FOUNDER",
  ADVISOR: "ADVISOR",
  EMPLOYEE: "EMPLOYEE",
  CONSULTANT: "CONSULTANT",
  INVESTOR: "INVESTOR",
  OPTIONHOLDER: "OPTIONHOLDER",
  CONVERTIBLEHOLDER: "CONVERTIBLEHOLDER",
  WARRANTHOLDER: "WARRANTHOLDER",
  SHAREHOLDER: "SHAREHOLDER",
  IPASSIGNOR: "IPASSIGNOR",
  THIRDPARTY: "THIRDPARTY",
  DIRECTOR: "DIRECTOR",
  AUTHORIZEDSIGNATORY: "AUTHORIZEDSIGNATORY",
  UBO: "UBO"
}

const ROLES_LABEL_PLURAL = {
  FOUNDER: "Founders",
  ADVISOR: "Advisors",
  EMPLOYEE: "Employees",
  CONSULTANT: "Consultants",
  INVESTOR: "Investors",
  OPTIONHOLDER: "Option Holders",
  CONVERTIBLEHOLDER: "Convertible Holders",
  WARRANTHOLDER: "Warrant Holders",
  SHAREHOLDER: "Shareholders",
  IPASSIGNOR: "IP Assignors",
  THIRDPARTY: "Third Parties",
  DIRECTOR: "Directors",
  AUTHORIZEDSIGNATORY: "Authorised Signatories",
  UBO: "UBO"
};

const ADD_ENTITY_LABEL={
  ...ROLES_LABEL,
  COMPANY:'Company'
}

const ROLES_FROM_BACKEND = {
  FOUNDER: {
    id: "FOUNDER",
    label: "Founder"
  },
  DIRECTOR: {
    id: "DIRECTOR",
    label: "Director"
  },
  ADVISOR: {
    id: "ADVISOR",
    label: "Advisor"
  },
  EMPLOYEE: {
    id: "EMPLOYEE",
    label: "Employee"
  },
  CONSULTANT: {
    id: "CONSULTANT",
    label: "Consultant"
  },
  INVESTOR: {
    id: "INVESTOR",
    label: "Investor"
  },
  SHAREHOLDER: {
    id: "SHAREHOLDER",
    label: "Shareholder"
  },
  CONVERTIBLEHOLDER: {
    id: "CONVERTIBLEHOLDER",
    label: "Convertible Holder"
  },
  WARRANTHOLDER: {
    id: "WARRANTHOLDER",
    label: "Warrant Holder"
  },
  OPTIONHOLDER: {
    id: "OPTIONHOLDER",
    label: "Option Holder"
  },
  IPASSIGNOR: {
    id: "IPASSIGNOR",
    label: "IP Assignor"
  },
  THIRDPARTY: {
    id: "THIRDPARTY",
    label: "Third Party"
  },
  AUTHORIZEDSIGNATORY: {
    id: "AUTHORIZEDSIGNATORY",
    label: "Authorised Signatory"
  },
  UBO: {
    id: "UBO",
    label: "Beneficial Owner"
  }
};

const ROLES_GROUPS = {
  TEAM: ["FOUNDER", "ADVISOR", "EMPLOYEE", "CONSULTANT", "INVESTOR", "THIRDPARTY"],
  EQUITY: ["OPTIONHOLDER", "CONVERTIBLEHOLDER", "WARRANTHOLDER", "SHAREHOLDER", "UBO"],
  IP: ["IPASSIGNOR"],
  GOVERNANCE: ["DIRECTOR", "AUTHORIZEDSIGNATORY"],
};

const DEFAULT_MESSAGES = {
  VALIDATION: {
    pattern: "Error Pattern",
    required: "Field required"
  }
};

const CONTENTFUL = {
  ENTRIES: {
    REGISTER: process.env.CONT_REGISTRE_ENTRY_ID || "3fsPvVYTrgJ93uuE7pw21p",
    WELCOME: process.env.CNTFL_WELCOME_ENTRY_ID || "1oZeODfVyMQ0S73E9R5amg",
    KNOW_HOW: process.env.CTNFL_KNOW_HOW_ENTRY_ID || "5dup66ltl7ab1dC5UDmXTo",
    FORMS: {
      AGREEMENT: {
        GENERATE: {
          WEBSITE_TERMS_OF_USE: {
            LEFT_EDUCATION_ID:"36surBQz9koJ23lNa1wfTI",
            TOOLTIP:"2goSd6A3w6jt48Ax0Roq3b"
          },
          WEBSITE_COOKIE_NOTICE:{
            LEFT_EDUCATION_ID:"4swahOnfuuY5w4DSjP7bxG",
            TOOLTIP:"5Oa6tSLWgsMKOC3TUyua5Z"
          },
          WEBSITE_PRIVACY_POLICY: {
            LEFT_EDUCATION_ID:"7LE98nEidzpEnNCMO80aVB",
            TOOLTIP:"2UdkEyqfmHqZfKZlWqg7sZ"
          },
          WEBSITE_ACCEPTABLE_USE_POLICY: {
            LEFT_EDUCATION_ID:"1W2V5I0uviMfVKoSvNBQPS",
            TOOLTIP:"hH29qt7tAtjkHNc4oxTNu"
          },
          IP_ASSIGNMENT: {
            LEFT_EDUCATION_ID: "4sgKxYCFQVm7DGlJbaXumw",
            TOOLTIP: "4alkDjHTn1B7ohKyCp2XZg"
          },
          SAFE: {
            LEFT_EDUCATION_ID: "1W1c3iDL7HzUM24y90aUPz",
            TOOLTIP: "4YNgbI59YstmJsMxD07O1a"
          },
          CONVERTIBLE_NOTE: {
            LEFT_EDUCATION_ID: "7qzSyr4QYjplbZfi2NVHh1",
            TOOLTIP: "5lbHpp5uJI75BAxo3HMIIy"
          },
          ADVISOR_AGREEMENT: {
            LEFT_EDUCATION_ID: "2voIrgQtUL0GdNE2tRvEui",
            TOOLTIP: "6vM6N97bhBMYR0MKTbug3I"
          },
          CONSULTANT_AGREEMENT: {
            LEFT_EDUCATION_ID: "6W5YSLIxFqrcFyM1O8KmSi",
            TOOLTIP: "4ePxE063hj2i8AXiRs7ThX"
          },
          EMPLOYMENT_AGREEMENT_ADGM: {
            LEFT_EDUCATION_ID: "7KBjfb2pTxDrq8Ciau6RGv",
            TOOLTIP: "2SCPeDS5VdOshlxUMNaINB"
          },
          USA_CAL_EMPLOYEE_INFORMATION_AND_INVENTIONS_AGREEMENT:{
            LEFT_EDUCATION_ID: "1RiquqjYORcG5TtlAm7BG0",
            TOOLTIP: "r5nhVE9nT1n4bN58YCshv"
          },
          USA_CONSULTANT_INFORMATION_AND_INVENTIONS_AGREEMENT:{
            LEFT_EDUCATION_ID: "4ksnnx7nbZTYvWLaGHIIxJ",
            TOOLTIP: "191imEhGbNFXEXL4DUgObC"
          },
          USA_CONSULTING_AGREEMENT:{
            LEFT_EDUCATION_ID: "3Srzx9CD8w8KdYXhCVwN0s",
            TOOLTIP: "1wAJCIMZMNwZQIbriALCU3"
          },
          USA_STRATEGIC_ADVISORY_BOARD_LETTER:{
            LEFT_EDUCATION_ID: "7ywZIITr83eaNoIbJ19Dd",
            TOOLTIP: "2qORrpZeaFnJyWpgX5o0vY"
          },
          EMPLOYMENT_AGREEMENT_DIFC: {
            LEFT_EDUCATION_ID: "6kmCaA7FE9tWCA4FRDVeKj",
            TOOLTIP: "1QflfUYW31rySSot71aV8n"
          },
          USA_BOARD_CONSENT_SAFE:{
            LEFT_EDUCATION_ID: "5mbXKn2q32znRkZlMW5UTn",
            TOOLTIP: "26uw73BEQQtl56pttU3Cwi"
          },
		  EMPLOYMENT_AGREEMENT_UK: {
			LEFT_EDUCATION_ID: "5z70Dz73UHBSNBeoIXPsYv",
			TOOLTIP: "28TQiWtYEd2tOmyGyJZO1y"
		  },
		  FOUNDER_SERVICES_AGREEMENT: {
        LEFT_EDUCATION_ID: "1kJTF9a6xEXkOqX2j2uwn8",
        TOOLTIP: "4SjDJJYBDTJrrkCQr2KDoJ",
      },
      SHARE_INCENTIVE_PLAN: {
        LEFT_EDUCATION_ID: '5ciBUrxiQVuRYdB2TIMpHn',
        TOOLTIP: 'OuOFXpdkGNDvPZJVwC3S2'
      },
      GRANT_AGREEMENT: {
        LEFT_EDUCATION_ID: '5OrhwWpzT4WnLjH6QgDafz',
        TOOLTIP: '2Y6l545Cha095vWuti8cqq'
      },
      RESTRICTIVE_COVENANT_AGREEMENT: {
        LEFT_EDUCATION_ID: '7IawG0iB4z6tvdrkBqHnYF',
        TOOLTIP: '664p9W0zVHnROaTEflcnWr'
      },
		  EXERCISE_NOTICE: {
			LEFT_EDUCATION_ID: '7mt5G8jleAb3OQJMvsvnZl',
			TOOLTIP: '664p9W0zVHnROaTEflcnWr'
		  },
		  NDA_MUTUAL: {
        LEFT_EDUCATION_ID: '5OSEMXk7kXdi796ADAYe5N',
        TOOLTIP: '4Gq1K25Wr0q9WHgOb5GVVQ'
      },
      FOUNDERS_AGREEMENT:{
        LEFT_EDUCATION_ID:"4ztGXBqkt9dw6LJxWv1iud",
        TOOLTIP:"3aXkkwcmvOl0DFgQK6hS3l"
      },
		  BOARD_RESOLUTION_CONVERTIBLE: {
			LEFT_EDUCATION_ID: "7Lv8WqFhdXksNYmJCROOYI",
			TOOLTIP: "3lJAJDsPCrX09BWFagbjJN"
		  },
      USA_NDA: {
        LEFT_EDUCATION_ID: '6oJOJzhKWDlmGKgrMijJsy', 
        TOOLTIP: 'vZ37on7dubkwycTSXeHKT'
      },
      USA_CAL_OFFER_LETTER_SALARY_EMPLOYEE: {
        LEFT_EDUCATION_ID: '63S0ciWgZZqmSku6PimH8t', 
        TOOLTIP: '5qa6iw0EzLW6tA9sqAkAge'
      },
      USA_EQUITY_INCENTIVE_PLAN:{
        LEFT_EDUCATION_ID: '1LEc4TunnDK6WVUDbQvxYp', 
        TOOLTIP: '3qGU8oV6EkItck8KCfhAes'
      },
      USA_BOARD_APPROVING_OPTION_GRANT:{
        LEFT_EDUCATION_ID: '5wYE6Hu4olklhJMOEL82hq', 
        TOOLTIP: '4kIRuglswjLc18XDfqId8F'
      },
      USA_OPTION_GRANT_NOTICE:{
        LEFT_EDUCATION_ID: '1j9pLVSmMrp2S9hf3VxyXf', 
        TOOLTIP: '1v6eRevCozersQhSGslj4Z'
      },
        POST_MONEY_VALUATION_CAP:{
        LEFT_EDUCATION_ID:'66nIwoH8owB4uKEJzMKyHe'
      },
      POST_MONEY_VALUATION_CAP_USA:{
        LEFT_EDUCATION_ID:'6CGCrQoW3uDiqOVW6Etnzl'
      },
      SAFE_DISCOUNT_ONLY_USA:{
      LEFT_EDUCATION_ID:'5KO48GWLtfv5Gr3NU7KMeY'
    },
    SAFE_MFN_ONLY_USA:{
      LEFT_EDUCATION_ID:'60wtQl6CPpYctKu8pf0cpn'
    },
    POST_MONEY_VALUATION_CAP_SINGAPORE:{
      LEFT_EDUCATION_ID:'2KZBkMYtHROIfHdnelQsFv'
    },
    'PRO_RATA_SIDE_LETTER_SINGAPORE':{
      LEFT_EDUCATION_ID:'H6WbVeOMVe9YaHMY2Qhk9'
    },
    'PRO_RATA_SIDE_LETTER_CAYMAN':{
      LEFT_EDUCATION_ID:'4jwEBrBW7xAnVzzypOEFGR'
    },
    'PRO_RATA_SIDE_LETTER_USA':{
      LEFT_EDUCATION_ID:'OIzDmg4420d2VHmCbK4ss'
    }
		},
        ADD: {
          LEFT_EDUCATION_ID: "6QToTxzIW36K4uF1IOc0rI",
          TOOLTIP: "3vPk3x5QeHKspQVoLTs4m9"
        }
      },
      STARTUP: {
        LEFT_EDUCATION_ID: "63r8L1Y1u4uGh8LVrGgQAP",
        TOOLTIP: "4MIBXdYBpF1SUGcSJi0PcZ"
      },
      STAKEHOLDER: {
        LEFT_EDUCATION_ID: "206fiQGZFDMaqoHMtI0vSL",
        TOOLTIP: "472SSBKmvMy7Un1qrAZMsQ",
        EDIT: {
          TOOLTIP: "l9peDMAVarlyx5XLZuhrD"
        }
      },
      GROUPCOMPANY: {
        LEFT_EDUCATION_ID: "3bs67VRTjntF4xO8pAxdsL",
        TOOLTIP: "6lGEIsKjScpniAXYxGmfI6"
      },
      DUEDILIGENCE: {
        LEFT_EDUCATION_ID: "35gYIgEodj7YhgErhOcHgd",
        TOOLTIP: "5atpel58aTG7WfA4J1bCkb"
      },
      KYC_INDIVIDUAL_FORM: {
        ADD: {
          LEFT_EDUCATION_ID: "681DT4jCNpg4wuuhkvad79",
          TOOLTIP: "5atpel58aTG7WfA4J1bCkb"
        },
        EDIT: {
          LEFT_EDUCATION_ID: "1zvAzNWy2ISduZk2ZC0vVt",
          TOOLTIP: "5atpel58aTG7WfA4J1bCkb"
        }
      },
      KYC_COMPANY_FORM: {
        ADD: {
          LEFT_EDUCATION_ID: "7m7EtxtHOFZtBAvTmGlzYr",
          TOOLTIP: "5atpel58aTG7WfA4J1bCkb"
        },
        EDIT: {
          LEFT_EDUCATION_ID: "20CSLyi83CgCKpbMY99bCN",
          TOOLTIP: "5atpel58aTG7WfA4J1bCkb"
        }
      },
      PROPOSAL_FORM: {
        ADD: {
          LEFT_EDUCATION_ID: "7MpdxCzWFtsXHU2dciNfJk",
          TOOLTIP: "5atpel58aTG7WfA4J1bCkb"
        },
        EDIT: {
          LEFT_EDUCATION_ID: "1KQoU9HX1agPoB1WqE1Ui8",
          TOOLTIP: "5atpel58aTG7WfA4J1bCkb"
        }
      },
      ENGAGEMENT_FORM: {
        ADD: {
          LEFT_EDUCATION_ID: "4HO1bAaDvfFBcsa0flxhEN",
          TOOLTIP: "5atpel58aTG7WfA4J1bCkb"
        },
        EDIT: {
          LEFT_EDUCATION_ID: "QRqynjfsbD6ydxnlhDL71",
          TOOLTIP: "5atpel58aTG7WfA4J1bCkb"
        }
      },
      KYC_ON_BEHALF_FORM: {
        ADD: {
          LEFT_EDUCATION_ID: "3kM26XXhiNGUWcJWejZTJF",
          TOOLTIP: "5atpel58aTG7WfA4J1bCkb"
        },
        EDIT: {
          LEFT_EDUCATION_ID: "3QnZHNLpbCt5XjiWeneZ56",
          TOOLTIP: "5atpel58aTG7WfA4J1bCkb"
        }
      },
      EQUITY_POSITIONS: {
        CARD_TOOLTIPS: "7mZHINrwGzwvaH4ro953er",
        SHARES: {
          LEFT_EDUCATION_ID: "70CVnhutL8ou2fnOuDsQpj",
          TOOLTIPS: "4kNQTGr3Ohk0Rsax3wB6vy"
        },
        CONVERTIBLES: {
          LEFT_EDUCATION_ID: "Oe1hpHkbnzbUp8aHfBUf5",
          TOOLTIPS: 'R7vwJy61ZoqxcaWjdlcf6'
        },
        OPTIONS: {
          LEFT_EDUCATION_ID: "4CY0nAOlEgIw5v95DygFuZ",
          TOOLTIPS: "7rF3SiyIKIqLx4jzR9lyel"
        },
        WARRANTS: {
          LEFT_EDUCATION_ID: "1kp8PpMhVWrN3nNTT60k1p",
          TOOLTIPS: "3on9vZ4X4sE6yWDR6v2RfP"
        }
      }
    }
  },
  ASSETS: {
    RESIDENTIAL_ADDRESS_TEMPLATE: "2YquyuppJLTRiFiHJCLvN2",
  	ADGM_TSL_BUSINESS_PLAN_TEMPLATE: "74hsUzru24v0wl9JN8Gcbp"
  },
  CONTEXTS: {
    DASHBOARD: "Dashboard",
    ONBOARDING: "Onboarding Venue Details",
    ADD_GROUP_COMPANY: "Add Group Company",
    EDIT_PROFILE: "Add Stakeholder",
    ADD_STARTUP: "Add Startup"
  }
};
const OPERATIONS = {
  DASHBOARDADDBUTTON: "dashboardAddButton",
  CLARASCORE: "clarascore",
  MODALEDIT: "modaledit"
};

const STAKEHOLDER_MAP = {
  SHOW_EQ_MAP: "SHOW_EQ_MAP",
  SHOW_GOV_MAP: "SHOW_GOV_MAP",
  SHOW_IP_MAP: "SHOW_IP_MAP",
  SHOW_SH_MAP: "SHOW_SH_MAP",
  SHOW_TP_MAP: "SHOW_TP_MAP"
};

const FORMIK_CONSTANTS = {
  DELETED_FIELDS: {
    STRING: "DEL__XX___DEL",
    NUMBER: 909090909090909090909090909090909090909090909090909088888888888888888888
  }
};
const DOCUMENTS_TYPES = {
  STARTUP: [
    "Articles of Association",
    "Certificate of Incorporation",
    "Register of Directors",
    "Register of Shares",
    "Share Incentive Plan",
    "Trade License",
    "Memorandum of Association",
    "Other"
  ],
  STAKEHOLDER: {
    INDIVIDUAL: ["Passport", "Proof of Address", "Other"],
    COMPANY: [
      "Articles of Association",
      "Certificate of Incorporation",
      "Register of Directors",
      "Register of Shares",
      "Share Incentive Plan",
      "Trade License",
      "Memorandum of Association",
      "Other"
    ]
  },
  GROUPCOMPANY: [
    "Articles of Association",
    "Certificate of Incorporation",
    "Register of Directors",
    "Register of Shares",
    "Share Incentive Plan",
    "Trade License",
    "Memorandum of Association",
    "Other"
  ]
};
/*
const ISSUES_CATEGORIES = {
  CORPORATE_STRUCTURE: {
    key: "corporateStructure",
    title: "Corporate Structure",
    subCategories: [
      {
        title: "Constitutional Documents",
        key: "constitutionalDocuments",
        documentTypes: [
          {
            key: "MEMORANDUM_OF_ASSOCIATION",
            title: "Memorandum of Association"
          },
          {
            key: "ARTICLES_OF_ASSOCIATION",
            title: "Articles of Association"
          },
          {
            key: "CERTIFICATE_OF_INCORPORATION",
            title: "Certificate of Incorporation"
          },
          {
            key: "TRADE_LICENSE",
            title: "Commercial License"
          },
          {
            key: "REGISTER_OF_DIRECTORS",
            title: "Register of Directors"
          },
          {
            key: "REGISTER_OF_SHARES",
            title: "Register of Shares"
          },
          {
            key: "SHARE_CERTIFICATE",
            title: "Share Certificates"
          },
          {
            key: "SHARE_RESOLUTION",
            title: "Share Resolution"
          },
          {
            key: "BOARD_RESOLUTION",
            title: "Board Resolution"
          },
          {
            key: "OTHER_CONSTITUTIONAL_DOC",
            title: "Constitutional Documents: Other"
          }
        ]
      },
      {
        title: "Ownership",
        key: "ownership",
        documentTypes: [],
        hide: true
      },
      {
        title: "Jurisdiction",
        key: "jurisdiction",
        documentTypes: [],
        hide: true
      }
    ]
  },
  EQUITY: {
    key: "equity",
    title: "Equity",
    subCategories: [
      {
        title: "Share Purchase Agreements",
        key: "sharePurchaseAgreements",
        documentTypes: [
          {
            key: "SHARE_PURCHASE_AGREEMENTS",
            title: "Share Purchase Agreement"
          },
          {
            key: "OTHER_SHARE_PURCHASE_AGREEMENT",
            title: "Share Purchase Agreements: Other"
          }
        ]
      },
      {
        title: "Subscription Agreements",
        key: "subscriptionAgreements",
        documentTypes: [
          {
            key: "SHARE_SUBSCRIPTION",
            title: "Share Subscription Agreement"
          },
          {
            key: "OTHER_SUBSCRIPTION_AGREEMENT",
            title: "Subscription Agreements: Other"
          }
        ]
      },
      {
        title: "Share Options Agreements",
        key: "shareOptionsAgreements",
        documentTypes: [
          {
            key: "GRANT_AGREEMENT",
            title: "Grant Agreement"
          },
          {
            key: "EXERCISE_NOTICE",
            title: "Exercise Notice"
          },
          {
            key: "SHARE_INCENTIVE_PLAN",
            title: "Share Incentive Plan"
          },
          {
            key: "OTHER_SHARE_OPTION_AGREEMENT",
            title: "Share Options Agreements: Other"
          }
        ]
      },
      {
        title: "Convertible Instruments",
        key: "convertibleInstruments",
        documentTypes: [
          {
            key: "CONVERTIBLE_NOTE",
            title: "Convertible Note"
          },
          {
            key: "KISS",
            title: "Keep It Simple Security"
          },
          {
            key: "SAFE",
            title: "Simple Agreement for Future Equity"
          },
          {
            key: "WARRANT_AGREEMENT",
            title: "Warrant Agreement"
          },
          {
            key: "OTHER_CONVERTIBLE_AGREEMENT",
            title: "Share Options Convertible Instruments: Other"
          }
        ]
      }
    ]
  },
  GOVERNANCE: {
    key: "governance",
    title: "Governance",
    subCategories: [
      {
        title: "Shareholders Agreements",
        key: "shareHoldersAgreements",
        documentTypes: [
          {
            key: "FOUNDERS_TERM_SHEET",
            title: "Founders Term Sheet"
          },
          {
            key: "FOUNDERS_AGREEMENT",
            title: "Founders Agreement"
          },
          {
            key: "SHAREHOLDERS_AGREEMENT",
            title: "Shareholders Agreement"
          },
          {
            key: "OTHER_SHAREHOLDER_AGREEMENT",
            title: "Shareholders Agreements: Other"
          }
        ]
      },
      {
        title: "Intra-group Agreements",
        key: "intra_agreements",
        documentTypes: [
          {
            key: "INTRAGROUP_AGREEMENT",
            title: "Intra-Group Agreement"
          },
          {
            key: "OTHER_INTRA-GROUP_AGREEMENT",
            title: "Intra-Group Agreements: Other"
          }
        ]
      },
      {
        title: "Related Party Transactions",
        key: "relatedParty",
        documentTypes: [
          {
            key: "RELATED_PARTY_AGREEMENT",
            title: "Related Party Agreement"
          },
          {
            key: "OTHER_RELATED_PARTY_AGREEMENT",
            title: "Related Party Agreements: Other"
          }
        ]
      }
    ]
  },
  TEAM: {
    key: "team",
    title: "Team",
    subCategories: [
      {
        title: "Founder Services Agreements",
        key: "founderServicesAgreements",
        documentTypes: [
          {
            key: "FOUNDER_SERVICES_AGREEMENT",
            title: "Founders Service Agreement"
          },
          {
            key: "MANAGEMENT_AGREEMENT",
            title: "Management Agreement"
          },
          {
            key: "OTHER_FOUNDER_SERVICE_AGREEMENT",
            title: "Founder Services Agreements: Other"
          }
        ]
      },
      {
        title: "Employment Agreements",
        key: "employmentAgreements",
        documentTypes: [
          {
            key: "EMPLOYMENT_AGREEMENT",
            title: "Employment Agreement"
          },
          {
            key: "EMPLOYMENT_AGREEMENT_ADGM",
            title: "Employment Agreement - ADGM"
          },
          {
            key: "OTHER_EMPLOYMENT_AGREEMENT",
            title: "Employment Agreements: Other"
          }
        ]
      },
      {
        title: "Consulting Agreements",
        key: "consultingAgreements",
        documentTypes: [
          {
            key: "CONSULTANT_AGREEMENT",
            title: "Consulting Agreement"
          },
          {
            key: "OTHER_CONSULTING_AGREEMENT",
            title: "Consulting Agreements: Other"
          }
        ]
      },
      {
        title: "Advisory Agreements",
        key: "advisoryAgreements",
        documentTypes: [
          {
            key: "ADVISOR_AGREEMENT",
            title: "Advisory Agreement"
          },
          {
            key: "OTHER_ADVISORY_AGREEMENT",
            title: "Advisory Agreements: Other"
          },
        ]
      }
    ]
  },
  INTELLECTUAL_PROPERTY: {
    key: "intellectualProperty",
    title: "Intellectual Property",
    subCategories: [
      {
        title: "Registered IP",
        key: "registeredIP",
        documentTypes: [
          {
            key: "IP_REGISTRATION_DOCUMENT",
            title: "IP Registration Document"
          },
          {
            key: "OTHER_REGISTERED_IP",
            title: "Registered IP: Other"
          }
        ]
      },
      {
        title: "Unregistered IP",
        key: "unregisteredIP",
        documentTypes: [
          {
            key: "UNREGISTERED_IP_DOCUMENT",
            title: "Unregistered IP Document"
          },
          {
            key: "OTHER_UNREGISTERED_IP",
            title: "Unregistered IP: Other"
          }
        ]
      },
      {
        title: "IP Infringement",
        key: "IPInfringement",
        documentTypes: [
          {
            key: "IP_INFRINGEMENT_DOCUMENT",
            title: "IP Infringement Document"
          }
        ]
      },
      {
        title: "IP Assignment",
        key: "IPAssignment",
        documentTypes: [
          {
            key: "IP_ASSIGNMENT",
            title: "IP Assignment Agreement"
          },
          {
            key: "OTHER_IP_ASSIGNMENT",
            title: "IP Assignment: Other"
          }
        ]
      }
    ]
  },
  OTHER: {
    key: "other",
    title: "Other",
    subCategories: [
      {
        title: "Litigation",
        key: "litigation",
        documentTypes: [
          {
            key: "LITIGATION_DOCUMENT",
            title: "Litigation Document"
          }
        ]
      },
      {
        title: "Material Agreements",
        key: "materialAgreements",
        documentTypes: [
          {
            key: "KEY_CUSTOMER_AGREEMENT",
            title: "Key Customer Agreement"
          },
          {
            key: "KEY_SUPPLIER_AGREEMENT",
            title: "Key Supplier Agreement"
          },
          {
            key:"RESTRICTIVE_COVENANT_AGREEMENT",
            title:"Restrictive Covenant Agreement"
          }
        ]
      },
      {
        title: "Real Estate",
        key: "realEstate",
        documentTypes: [
          {
            key: "REAL_ESTATE_DOCUMENT",
            title: "Real Estate Document"
          }
        ]
      },
      {
        title: "Finance",
        key: "financialDebt",
        documentTypes: [
          {
            key: "FINANCIAL_DEBT_DOCUMENT",
            title: "Financing/Debt Document"
          }
        ]
      },
      {
        title: "Investor Updates",
        key: "investorUpdates",
        documentTypes: [
          {
            key: "INVESTOR_UPDATES",
            title: "Investor Updates"
          }
        ]
      },
      {
        title: "Miscellaneous",
        key: "miscellaneous",
        documentTypes: [
          {
            key: "MISCELLANEOS_DOCUMENT",
            title: "Miscellaneous Document"
          }
        ]
      }
    ]
  }
};
*/

const INPUT_TYPES = {
  SELECT: "SELECT",
  TEXT: "TEXT",
  FILE: "FILE",
  DATE: "DATE",
  CHECKBOX: "CHECKBOX",
  RADIO: "RADIO"
};
const PROFILE_ROLES = {
  SUPERADMIN: "SUPERADMIN",
  RESELLER: "RESELLER",
  ADMIN: "ADMIN",
  SERVICE_AGENT: "SERVICE_AGENT",
  SERVICE_AGENT_MANAGER: "SERVICE_AGENT_MANAGER",
  SENIOR_SERVICE_AGENT: "SENIOR_SERVICE_AGENT",
  USER: "USER"
  // ADMIN: "ADMIN",
  // REVIEWER: "REVIEWER",
  // FOUNDER_RW: "FOUNDER_RW",
  // FOUNDER_CP: "FOUNDER_CP",
  // FOUNDER: "FOUNDER",
  // INVESTOR: "INVESTOR",
  // INTRODUCTION: "INTRODUCTION",
  // SERVICE_AGENT: "SERVICE_AGENT",
  // SERVICE_AGENT_MANAGER: "SERVICE_AGENT_MANAGER",
  // SENIOR_SERVICE_AGENT: "SENIOR_SERVICE_AGENT",
  // FORMATION: "FORMATION",
  // SUPERADMIN: "SUPERADMIN",
  // FOUNDER_AGREEMENT: "FOUNDER_AGREEMENT"
};
const USER_ROLES = {
  USER_OWNER: 'USER_OWNER',
  USER_MANAGER: 'USER_MANAGER',
  USER_EDITOR: 'USER_EDITOR',
  USER_VIEWER: 'USER_VIEWER',
  USER_COLLABORATOR:"USER_COLLABORATOR"
}
const SIGNUP_TYPES = {
  INVITE: "INVITE",
  REGISTER: "REGISTER",
  FORMATION: "FORMATION",
  SIGNUP: "SIGNUP"
};
const JURISDICTIONS = {
  ADGM: "ADGM",
  CAYMAN: "CAYMAN",
  DELAWARE: "DELAWARE",
}
const ACTIONS = actionsConstants;
const PRODUCTS = productsConstants;
const STATUS_PLANS=statusPlansConstants;
const SUBSCRIPTIONS_TYPES=subscriptionsTypesConstants;
const BUSINESS_RULES = businessRulesConstants;

const ORDERS_KEYS = {
  ASC: "ASC",
  DESC: "DESC"
};

const EQUITY_POSITIONS = {
  SHARES: "SHARES",
  CONVERTIBLES: "CONVERTIBLES",
  OPTIONS: "OPTIONS",
  WARRANTS: "WARRANTS"
};

const CARD_TYPES = {
  VISA: "VISA",
  MASTERCARD:"MASTERCARD"
}
const EMAIL_MANAGER = {
  AUTOMATIC_MATTER_SA_EMAIL: process.env.AUTOMATIC_MATTER_SA_EMAIL || "claraformations.krueunpf@mailosaur.io"
}

const HTTP_ERROR_CODES = {
  UNAUTHORIZED: {
    name: "Unauthorized",
    statusCode:  400
  },
  BAD_REQUEST: {
    name: "BAD_REQUEST",
    statusCode:  400
  },
  FORBIDDEN_REQUEST: {
    name: "FORBIDDEN_REQUEST",
    statusCode:  403
  }
}


// Localhost
//const GRAPH_VIEW_CLIENT = "http://localhost:3003/graphql";

// Seismic
//const GRAPH_VIEW_CLIENT = "https://seismic.clara.co:8443/graphMap/graphql";

// server url

let GRAPH_VIEW_CLIENT;

if (process.env.REACT_APP_API_URL) {
  GRAPH_VIEW_CLIENT = process.env.REACT_APP_API_URL + "/graphMap/graphql";
} else {

  GRAPH_VIEW_CLIENT =
    window.location.protocol +
    "//" +
    window.location.hostname +
    "/graphMap/graphql";
}
const Constants = {
  DOCUMENT_TYPE_IDENTITY_DOCUMENTS,
  GRAPH_VIEW_CLIENT,
  CARD_TYPES,
  JURISDICTIONS,
  NodeTypes: NodeTypes,
  CHAT_COMPONENTS_TYPE: CHAT_COMPONENTS_TYPE,
  URLS: Urls,
  BACKGROUND_TYPE: BACKGROUND_TYPE,
  AGREEMENT_STATUS: AGREEMENT_STATUS,
  ROLES: ROLES,
  ROLES_CODE,
  SIGNUP_TYPES: SIGNUP_TYPES,
  FORMS: FORMS,
  DEFAULT_MESSAGES: DEFAULT_MESSAGES,
  CONTENTFUL: CONTENTFUL,
  EMAIL_MANAGER,
  OPERATIONS,
  ROLES_LABEL_PLURAL,
  STAKEHOLDER_MAP,
  FORMS_MODE,
  COUNTRIES,
  COUNTRYCODES,
  CURRENCIES,
  ADD_ENTITY_LABEL,
  ROLES_GROUPS,
  FORMIK_CONSTANTS,
  DOCUMENTS_TYPES,
  //ISSUES_CATEGORIES,
  INPUT_TYPES,
  ROLES_LABEL,
  PROFILE_ROLES,
  USER_ROLES,
  ACTIONS,
  PRODUCTS,
  STATUS_PLANS,
  SUBSCRIPTIONS_TYPES,
  BUSINESS_RULES,
  ORDERS_KEYS,
  EQUITY_POSITIONS,
  PAGES: pagesConstants,
  EVENTS: eventsConstants,
  CONFIG: configConstants,
  LAYOUTS: layoutsConstants,
  ROLES_FROM_BACKEND: ROLES_FROM_BACKEND,
  ISSUES_STATUS: issuesStatusConstants,
  TRANSLATES: translateConstants,
  TYPE_FORMATS: typeFormatsConstants,
  FORMATS: formatsConstants,
  AUDITLOGS_TRANSACTIONS: auditLogTransactions,
  HEALTH_CHECK: healthCheck,
  COMPANY_TYPES: companyTypes,
  QUESTIONNAIRE_KEYS: questionnaireKeys,
  ENTITY_TYPES: entityTypes,
  INCORPORATIONS: incorporations,
  PORTAL_DOCUMENTS_KEYS: portalDocumentsKeys,
  REGEXP: regexp,
  FORM_TYPES: formsTypes,
  LABEL_FIELDS_FORM_GENERATE: labelFieldsFormGenerate,
  LOGGER: logger,
  MODULES: modules,
  HTTP_ERROR_CODES,
  PASSPORT_DOCUMENT_TYPES,
  VISA_STAMPS_RESIDENCE_ID_DOCUMENTS_TYPES,
  BLOCK_EXTENSIONS
};

export { Constants, FORMIK_CONSTANTS };
