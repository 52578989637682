import _ from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import Form from "src/components/forms/Form/Form";
import TemplateWizard from "src/modules//generic/templates/Modal/TemplateWizard";
import PaymentTotal from 'src/modules/checkout/components/PaymentTotal/PaymentTotal';
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import Typography from "src/modules/generic/components/Texts/Typography";
import * as Yup from 'yup';
import Button from "../../../../generic/components/Buttons/Button";
import useMachine from '../../../../generic/context/MachineContext/useMachine';
import useTranslate from '../../../../generic/hooks/useTranslate';
import cls from "./classes.module.scss";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import CheckboxButtonSimple from "src/modules/generic/components/Inputs/CheckboxButtonSimple";
import useFormat from 'src/modules/generic/hooks/useFormat';
import { Stack } from '@mui/material';
import { Constants } from 'src/v1/utils';
import { PaymentProvider } from 'src/modules/checkout/hooks/usePayment';

/**
 * Step to set pay data for manual invoice
 * @param {*} param0
 * @returns
 */
function SetPayData({ openParam }) {

  const [coupon, setCoupon] = useState(null)
  const [amount, setAmount] = useState(null)
  const { next, context, prev } = useMachine();
  const { translate } = useTranslate();
  const { moneyFormat } = useFormat()

  const handlePrevious = (values) => {
    prev();
  }

  const handleCompleteSubmit = useCallback( (values) => {
    next({ coupon, ...values,amount})
  },[coupon,amount,next])
  const handleChangeValue = useCallback((value) => {
    setAmount(value)
  },[setAmount])
  const optionsList = useMemo(() => {
    const products = [context?.initialData?.productMonthly, context?.initialData?.productAnnual];
    const filterData = _.orderBy(
      _.map(products, product => {
        return {
          label: (
            <Stack gap={0.5} direction="row" width={"100%"}>
              <Stack gap={1} direction="column" width={"100%"}>
                <Typography variant="h4" weight="bold">{translate(`MODAL_BLOCK_USER_PRODUCT_DESCRIPTION_${product.code}`)}</Typography>
                <Typography variant="h4" weight="bold">{product?.currency} {moneyFormat(product?.installmentValue)} + VAT</Typography>
              </Stack>
              {product.code === "SCALE_ANNUAL_SUBSCRIPTION" && (
                <Stack gap={1} direction="column" justifyContent={"flex-start"} alignContent={"flex-end"}>
                  <div className={cls.saveContainer}>Save 20%</div>
                </Stack>
              )}
            </Stack>
          ),
          value: product.code,
        }
      })
    );
    return filterData;
  }, [context]);

  const schema = Yup.object().shape({})
  const [product, setProduct] = useState(context?.initialData?.productCode === Constants.SUBSCRIPTIONS_TYPES.ANNUAL ? context?.initialData?.productAnnual : context?.initialData?.productMonthly)
  const paymentTotalRef = React.useRef(null);
  return (
    <PaymentProvider>
      <Form
        defaultValues={{ productCode: context?.initialData?.productCode }}
        schema={schema}
        onSubmit={handleCompleteSubmit}
        // ref={refForm}
        optionsForm={{ mode: "onChange" }}
        onlySendDirty={false}
      >
        <TemplateWizard
          props={{
            title: "Select a Scale Plan",
            skeleton: null
          }}
          buttons={{
            cancel: <Button onClick={handlePrevious} variant="secondary">{translate('BUTTON_PREVIOUS')}</Button>,
            submit: <SubmitButton >{translate('BUTTON_NEXT')}</SubmitButton>,
          }}
        >
          <div style={{ gridTemplateColumns: "1fr 1fr", gap: "3rem", display: "grid" }}>
            <div>
              <PaymentTotal
                innerRef={paymentTotalRef}
                isPaid={false}
                isProcess={false}
                product={product}
                vat={_.get(context, "initialData.product.defaultVAT")}
                freeVat={false}
                showBack={false}
                showTerms={false}
                showCostBox={false}
                className={cls.PaymentTotal}
                onChangeCoupon={(v) => {
                  setCoupon(v)
                }}
                onChangeValue={handleChangeValue }
              />
            </div>
            <div>
              <ControllerInput
                render={CheckboxButtonSimple}
                name="productCode"
                variant="col_1"
                size={"size2"}
                options={optionsList}
                onChange={value => {
                  paymentTotalRef?.current?.clearCoupn()
                  setProduct(value === Constants.SUBSCRIPTIONS_TYPES.ANNUAL ? context?.initialData?.productAnnual : context?.initialData?.productMonthly)
                }}
                className={cls.CheckboxButtonSimple}
              />
            </div>
          </div>
        </TemplateWizard>
      </Form>
    </PaymentProvider>
  );
}

export default SetPayData;
