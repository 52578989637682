import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { goToPage } from "src/store/actions/page";
import useDashboardActions from "../hooks/useDashboardActions";
import DashboardViewMF from "./DashboardViewMF";
import useNavigationCaptable from "../../capTable/CapTable/useNavigationCaptable";

const DashboardView = () => {
  const dispatch = useDispatch();
  const goTo = useNavigationCaptable();

  const goToMF = useCallback((page, params) => {
    goToPage(dispatch, { path: page }, params);
  },[]);

  const goToCaptable = useCallback((v, params) => {
    goTo(v, { ...params });
  }, [goTo]);

  const dashboardActions = useDashboardActions();

  return <DashboardViewMF goToMF={goToMF} goToCaptable={goToCaptable} dashboardActions={dashboardActions} />;
};

export default DashboardView;
