import { useMemo } from "react";
import TemplateWizardSkeletonMachine from "src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine";
import { SetPayData, BlockOneStartupViewStep, BlockOneStartupOwnerStep, BlockMoreOneStartupViewerStep, BlockMoreOneStartupOwnerStep, PaySuccessStep } from "../steps";
import CheckoutStep from "../steps/CheckoutStep";
import PayTimeOverdue from "../steps/PayTimeOverdue";
import ProductPlanStep from "../steps/ProductPlanStep";

const useSteps = () => {

  return useMemo(() => ({
    initialData: {
      render: TemplateWizardSkeletonMachine,
    },
    BlockOneStartupViewStep: {
      render: BlockOneStartupViewStep
    },
    BlockOneStartupOwnerStep: {
      render: BlockOneStartupOwnerStep
    },
    BlockMoreOneStartupViewerStep: {
      render: BlockMoreOneStartupViewerStep,
    },
    BlockMoreOneStartupOwnerStep: {
      render: BlockMoreOneStartupOwnerStep,
    },
    PayTimeOverdue:{
      render: PayTimeOverdue
    },
    ProductPlanStep: {
      render: ProductPlanStep
    },
    SetPayData: {
      render: SetPayData,
    },
    PaySuccessStep: {
      render: PaySuccessStep,
    },
    CheckoutPayment: {
      render: TemplateWizardSkeletonMachine,
    },
    FinishPayment: {
      render: TemplateWizardSkeletonMachine,
    },
    UpdateStartup: {
      render: TemplateWizardSkeletonMachine,
    },
    PayTimeOverdue:{
      render: PayTimeOverdue
    },
    CheckoutStep: {
      render: CheckoutStep,
    }
  }), []);
}

export default useSteps;