import { RoleFields } from "../../../../modules/stakeholder/graphql/typePolicies";
import useLazyQuery from "src/hooks/custom/useLazyQuery";

export const GET_STAKEHOLDERS = `
  ${RoleFields}
  query stakeholderList($startupId: ID!, $groupCompanyId: ID, $filters: StakeholderFilterInput){
    stakeholderList(startupId:$startupId, groupCompanyId: $groupCompanyId, filters: $filters){
      id
      isFounder
      avatar
      fullName
      roles { ...RoleFields }
      isAnEntity
    }
  }
`;




// New getStakeholders lazy query, need review is correct
const useGetStakeholdersLazy = ({ variables, query = GET_STAKEHOLDERS, ...props }, config) => {
  const { manualQuery, loading, error, data, refetch } = useLazyQuery(
    query, 
    { ...variables, ...(config?.isADGM ? { filters: { forADGM: true } } : {}) },
    config,
    props
  );
  return {
    loading,
    error,
    data,
    refetch,
    manualQuery
  };
};

export default useGetStakeholdersLazy;
