import Core from "./Core";
import _ from "lodash";

class GroupCompany extends Core {

  managedByClara: boolean;
  statusOnMatters: { onFields?: string[]; isRenewal?: boolean } = {};
  jurisdictionType: string;
  transactionCurrency: string;
  kind: string;
  postIncorporation: { strikeOffDate?: string } = {};
  requestBasket: any;
  licenseType: string;

  constructor(data) {
    super(data);
    
    this.managedByClara = data?.managedByClara;
    this.statusOnMatters = data?.statusOnMatters;
    this.jurisdictionType = data?.jurisdictionType;
    this.transactionCurrency = data?.transactionCurrency;
    this.kind = data?.kind;
    this.postIncorporation = data?.postIncorporation;
    this.requestBasket = data?.requestBasket;
    this.licenseType = data?.licenseType;
  }

  getStatusOnMatters = () => {
    return this.statusOnMatters;
  };

  getPostIncorporation = () => {
    return this.postIncorporation;
  };

  getJurisdiction = () => {
    return this.jurisdictionType;
  };
  getLicenseType = () => {
    return this.licenseType;
  }

  getCurrency = () => {
    return this.transactionCurrency;
  };

  isADGM = () => {
    return this.getJurisdiction() === "ADGM";
  };

  isCayman = () => {
    return this.getJurisdiction() === "CAYMAN";
  };

  isDelaware = () => {
    return this.getJurisdiction() === "DELAWARE";
  };
  isSPV = () => {
    return this.getLicenseType() === "SPV";
  }
  isTSL = () => {
    return this.getLicenseType() === "TSL";
  }
  isManagedByClara = () => {
    if (this.managedByClara === undefined || this.managedByClara === null) {
      return false;
    }
    return this.managedByClara;
  };

  isFormation = () => {
    return this.kind === "FORMATION";
  };

  isIncorporated = () => {
    return !this.isFormation();
  };

  getIsRenewal = (): boolean => {
    if (!this?.getStatusOnMatters()?.isRenewal) {
      return false;
    }
    return this.statusOnMatters?.isRenewal;
  };

  getIsDissolved = (): boolean => {
    if (!this?.getPostIncorporation()?.strikeOffDate) {
      return false;
    }
    const currentDate = new Date();
    const strikeOffDate = new Date(this.postIncorporation.strikeOffDate);
    return currentDate > strikeOffDate;
  };

  /**
   *  This function search in the stakeholder statusOnMatter.onFields array if the field is present
   *  with this logic you can detect pincas in fields like "name", "email", "phone", "address", "country", "city", "postalCode"
   */
  getFieldInMatter = (field: string) => {
    if (!this?.getStatusOnMatters()?.onFields) {
      return null;
    }
    return this.statusOnMatters?.onFields?.indexOf(field) > -1;
  };

  /**
   * Obtain the request item by type
   * @param type
   */
  getReqestItemByType = (type: string) => {
    const requestItem = _.filter(this.requestBasket?.requestItems, (r) => {
      return r?.type === type;
    });
    return requestItem;
  };

  /**
   * Return the id of the data protection contact in pincas (is only one value)
   * @returns
   */
  getIdInDataProtectionContact = () => {
    const pincas = this.getReqestItemByType('CHANGE_COMPANY_AMEND_DATA_PROTECTION_CONTACT');
    if (pincas && pincas.length > 0) {
      const id = pincas[0]?.values?.newValues?.keyPeople?.dataProtectionContact?.entityId;
      return id;
    }
    return null;
  };

    /**
   * Return the Ids of ADGM Authorize Signatories in pincas
   * @returns
   */
    getIdsADGMAuthirizeSignatoriesInPincas = () => {
      const pincas = this.getReqestItemByType('CHANGE_COMPANY_AMEND_AUTHORIZED_SIGNATORIES');
      if (pincas) {
        const entityId = _.map(pincas, "values.newValues.keyPeople.authorisedSignatories");
        return entityId;
      }
      return [];
    };

    /**
   * Return the Ids of directos in pincas
   * @returns
   */
    getIdsADGMDirectorsInPincas = () => {
      const pincasAdd = this.getReqestItemByType('CHANGE_STAKEHOLDER_ADD_ROLE_DIRECTOR');
      const pincasRemove = this.getReqestItemByType('CHANGE_STAKEHOLDER_REMOVE_ROLE_DIRECTOR');
      const pincas = pincasAdd.concat(pincasRemove);
      if (pincas) {
        const entityId = _.map(pincas, "entity.id");
        return entityId;
      }
      return [];
    };

}
export default GroupCompany;
