import _ from "lodash";
import React, { useState } from 'react';
import Form from '../../../../components/forms/Form/Form';
import SubmitButton from "../../../../components/generic/Buttons/SubmitButton/SubmitButton";
import Text from "../../../../components/text/Text/Text";
import Title from "../../../../components/text/Title/Title";
import Icon from "../../../../modules/generic/components/Icon";
import schema from "../../schemas/payment";
import classesModal from "./PaymentForm.module.scss";
import PaymentFormContent from "./PaymentFormContent";
import usePaymentMethodStripe from "./usePaymentMethods/usePaymentMethodStripe";



const PaymentForm = ({ canSelectCard, onCompleteForm, onErrorForm, onChangeVat, onChangeFreeVat, errorCard, errorMessageCard,couponCode, product, id, defaultVat = { discountApplies: false, value: 0, valueWithDiscount: 0 }, source, ...props }) => {
    const shouldRefetchQuery = (observer) => {
        switch (observer.queryName) {
            case "validatePaymentCoupon":
                return false;
            default:
                return true;
        }
    };
    const [card, setCard] = useState(null);

    const { paymentFull } = usePaymentMethodStripe({});


    const [isZipCodeRequired, setIsZipCodeRequired] = useState(false);


    const handleSubmit = async (_values) => {
        try {
            const { cardSelect, ...values } = _values;
            const result = await paymentFull(values,{ shouldRefetchQuery, source, couponCode, product, id, cardId:cardSelect });
            onCompleteForm(result?.url, !!cardSelect,result?.status)
        } catch (e) {
            // console.log("**** Error", e.message) 
            onErrorForm(e.message);
        }

    };

    const handleSetCard = (element) => {
        setCard(element);
    };

    const validateZipCode = (zipCode) => {
        if (isZipCodeRequired) {
            return zipCode && zipCode !== ""
        }
        return true;
    };

    return (
        <React.Fragment>
            <div className={classesModal.PaymentForm}>
                <Form initialValues={''} schema={schema(validateZipCode)} onSubmit={handleSubmit}>

                    <div>
                        <Title><Text uuid={"PAYMENT_CARD_TITLE"} /></Title>
                        <PaymentFormContent canSelectCard={canSelectCard} errorCard={errorCard} errorMessageCard={errorMessageCard} defaultVat={defaultVat} useCard={true} setIsZipCodeRequired={setIsZipCodeRequired} setCard={handleSetCard} product={product} onChangeVat={() => { }} onChangeFreeVat={onChangeFreeVat} />
                    </div>
                    <div className={classesModal.Botonera}>
                        <SubmitButton
                            labelLoading={<Text uuid={"BUTTON_PROCESSING"} />}
                            classLoading={classesModal.LoadingButton}
                            className={classesModal.Submit}>
                            <Text uuid={`MODULES.CHECKOUT.PAYMENT_BUTTON_UPGRADE_${_.get(product, "code")}`} />
                            <Icon icon={"Lock"} isClara={true} size={"1.2rem"} />
                        </SubmitButton>
                    </div>


                </Form>
                <div className={classesModal.Footer}>
                    <Text uuid={"PAYMENT_CARD_FOOTER"} />
                </div>

            </div>

        </React.Fragment >
    )
}

export default PaymentForm;
