// import React, { useEffect, useMemo, useState } from 'react';
import React, { useMemo } from 'react';
import classes from "./classes.module.scss";
import classnames from "classnames";
import PropTypes from "prop-types";
import NextIcon from "../../../../modules/generic/components/NextIcon";
import useTranslate from "../../../../modules/generic/hooks/useTranslate";
import _ from 'lodash';
import CheckList from '../../../../modules/generic/components/Lists/CheckList';
import useSession from '../../../../modules/session/hooks/useSession';

const BubbleNext = ({
  task,
  children,
  ...props
}) => {

  const { translate } = useTranslate();
  const { startup } = useSession();
  const acceleratorName = _.get(startup, "acceleratorDef.name", "");

  const handleName = (code, params) => {
    let result;
    switch (code) {
      case "ADGM_RENEWAL_STAKEHOLDERS_TASKS":
        result = params?.stakeholder?.fullName;
        break;
      case "TASK_PENDING_GROUP_COMPANY":
        result = params?.company?.name;
        break;
      case "TASK_PENDING_GROUP_COMPANY_FORMATION":
        result = params?.company?.name;
        break;
      case "TASK_PENDING_STAKEHOLDER":
        result = params?.stakeholder?.fullName;
        break;
      case "TASK_PENDING_STAKEHOLDER_FORMATION":
        result = params?.stakeholder?.fullName
        break;
      case "TASK_PENDING_GROUP_COMPANY_INCORPORATED":
        result = params?.company?.name;
        break;
      case "TASK_PENDING_GROUP_COMPANY_INCORPORATED_ACCELERATOR":
        result = params?.company?.name;
        break;
      case "ADGM_RENEWAL_GROUP_COMPANY_TASKS":
        result = params?.company?.name;
        break;
      default:
        result = "";
        break;
    }
    return result;
  }

  const handleShowNextLink = (code) => {
    let result = true;
    switch (code) {
      case "TASK_ADD_BUY_PACKAGE_BUY_REFER":
        result = false;
        break;
      case "TASK_ADD_ENGAGE_CONCIERGE_REFER":
        result = false;
        break;
      case "TASK_ADD_SUBMIT":
        result = false;
        break;
      case "TASK_ADD_SUBMIT_ACCELERATOR":
        result = false;
        break;
      case "TASK_ADD_DOWNLOAD_GENERIC_BUILD":
        result = false;
        break;
      default:
        result = true;
        break;
    }
    return result;
  }

  const { status, code, imageIcon, subTitle, help, onClick, onDelete, params, showHelp, showTask } = useMemo(() => {
    const code = _.get(task, "code");
    const params = _.get(task, "params");
    let status = null;
    switch (_.get(task, "status")) {
      case "PENDING":
        status = "addTask";
        break
      case "COMPLETED":
        status = "checked";
        break
      default:
        status = null;
    }
    return {
      status,
      code,
      imageIcon: _.get(task, "props.image"),
      subTitle: code ? translate("MODULES.ASSISTANCEV4.ASSISTANCE_MAP_NEXT_SUBTITLE_" + code, {
        company: handleName(code, params), params,
        acceleratorName: acceleratorName,
      }) : "",
      help: code ? translate("MODULES.ASSISTANCEV4.ASSISTANCE_MAP_NEXT_HELP_" + code) : "",
      params: params,
      onClick: _.get(task, "props.onClick"),
      onDelete: _.get(task, "props.onDelete"),
      showHelp: _.get(task, "props.showHelp"),
      showTask: handleShowNextLink(code),
    }
  }, [task, translate]);

  return (
    <div className={classnames(classes.BubbleContentsNext)}>
      <NextIcon
        subTitle={subTitle}
        showHelp={showHelp}
        help={help}
        imageIcon={imageIcon}
        removeSeparator={!showTask}
      />
      {showTask && (
        <CheckList
          variant={status}
          title={params && translate("MODULES.ASSISTANCEV4.ASSISTANCE_TASKS_MAP." + code, { user: handleName(code, params), params })}
          onClick={onClick ? () => onClick(params) : null}
          deleteClick={onDelete ? () => onDelete(params) : null}
        />
      )}
    </div>
  );
};

BubbleNext.propTypes = {
  /** Property status */
  task: PropTypes.any,
  /** Property children */
  children: PropTypes.node,
};

BubbleNext.defaultProps = {

};

export default BubbleNext;
