import { Box } from '@mui/material';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import useMachine from '../../../../generic/context/MachineContext/useMachine';


/**
 * Step to set pay data for manual invoice
 * @param {*} param0
 * @returns
 */
function IframeComponent({ onComplete, url,onLoad }) {





  const handler = (event) => {
    let data = event.data;

    if (typeof _.get(event, "data") == "string") {
      data = JSON.parse(event.data);
    }

    const status = _.get(data, "status")
    const ckoSessionId = _.get(data, "ckoSessionId")
    if (ckoSessionId) {

      setTimeout(() => {
        onComplete({ ckoSessionId, status })
      }, 1000);
    }


  };
  useEffect(() => {

    window.addEventListener("message", handler);
    return () => window.removeEventListener("message", handler);

  }, []);
  
  return (
    <Box sx={{ width: "100%", height: "100%" }}>

      <iframe src={url} onLoad={onLoad} title="checkout" style={{ width: "100%", height: "100%", border: "unset" }}></iframe>


    </Box>
  );
}

export default IframeComponent;
