import { useMemo, useCallback } from 'react';
import useCompanyCorporateMapTasks from '../../../hooks/services/useGetCompanyCorporate'
import useSession from '../../../../session/hooks/useSession'
import _ from "lodash";
import Constants from "./../hooks/constants_task"

/**
 * Component to get All Tasks
 * @returns 
 */

function useTasks() {

  const { session: { startup } } = useSession();
  const { data: dataMapAssistance, loading: loadingMapTasks } = useCompanyCorporateMapTasks({ startupId: startup.id });

  const generateParentTask = (tasks, taskCode) => {
    if (!Constants.GROUP_TASKS[taskCode]) {
      return {}
    }
    const firstPendingTaks = _.find(tasks, (task) => {
      const useToFind = Constants.GROUP_TASKS[taskCode].indexOf(task.code);
      const result = useToFind >= 0 && (task.status === "PENDING" || task.status === "IN_PROGRESS");
      return result;
    });
    if (!!firstPendingTaks) {
      return {
        status: "PENDING",
        params: firstPendingTaks.params
      }
    }
    const firstPendingCompleted = _.find(tasks, (task) => Constants.GROUP_TASKS[taskCode].indexOf(task.code) >= 0 && task.status === "COMPLETED");
    if (!!firstPendingCompleted) {
      return {
        status: "COMPLETED",
        params: firstPendingCompleted.params
      }
    }
    return null;
  }

  const addParentTask = (tasks, groupTasks, task, params = {}, evaluateExists = null) => {
    const taskParent = generateParentTask(groupTasks, task);
    if (taskParent) {
      if (!tasks[task]) {
        tasks[task] = [];
      }
      const exists = evaluateExists ? evaluateExists(params) : false;
      if (!exists) {
        tasks[task].push({
          status: taskParent.status === "IN_PROGRESS" ? 'PENDING' : taskParent.status,
          params: { ...params, ...taskParent.params }
        });
      }
    }
    return tasks;
  }

  const addAllParents = (tasks, groupTasks, task, params = {}, evaluateExists = null) => {
    const taskParents = generateAllParentTaskWithComplete(groupTasks, task);
    _.forEach(taskParents, (taskParent) => {
      if (taskParent?.params?.company?.managedByClara){
        if (!tasks[task]) {
          tasks[task] = []
        }
        let index = -1;
        if (evaluateExists) {
          index = evaluateExists({ ...params, ...taskParent.params });
        }
        const exists = index >= 0;
        if (!exists) {
          tasks[task].push({
            status: taskParent.status === "IN_PROGRESS" ? 'PENDING' : taskParent.status,
            params: { ...params, ...taskParent.params }
          });
        } else if (taskParent.status === "IN_PROGRESS" || taskParent.status === 'PENDING') {
          tasks[task][index].status = "PENDING";
        }
      }
    })
    return tasks;
  }

  const addParentTaskAccelerator = (tasks, groupTasks, task, params = {}, complete, evaluateExists = null) => {
    const taskParent = generateParentTask(groupTasks, task);
    if (taskParent) {
      if (!tasks[task]) {
        tasks[task] = []
      }
      const exists = evaluateExists ? evaluateExists(params) : false;
      if (!exists) {
        tasks[task].push({
          status: complete ? "COMPLETED" : (taskParent.status === "IN_PROGRESS" ? 'PENDING' : taskParent.status),
          params: { ...params, ...taskParent.params }
        });
      }
    }
    return tasks;
  }

  const generateAllParentTask = (tasks, taskCode) => {
    if (!Constants.GROUP_TASKS[taskCode]) {
      return {}
    }
    const pendingTasks = _.filter(tasks, (task) => Constants.GROUP_TASKS[taskCode].indexOf(task.code) >= 0 && (task.status === "PENDING" || task.status === "IN_PROGRESS"));
    return pendingTasks;
  }

  const generateAllParentTaskWithComplete = (tasks, taskCode) => {
    if (!Constants.GROUP_TASKS[taskCode]) {
      return {}
    }
    const pendingTasks = _.filter(tasks, (task) => Constants.GROUP_TASKS[taskCode].indexOf(task.code) >= 0 && (task.status === "PENDING" || task.status === "IN_PROGRESS" || task.status === "COMPLETED"));
    return pendingTasks;
  }

  const addAllFormationsParentTask = (tasks, groupTasks, task, params = {}, evaluateExists = null) => {
    const taskParents = generateAllParentTask(groupTasks, task);
    _.forEach(taskParents, (taskParent) => {
      if (!tasks[task]) {
        tasks[task] = []
      }
      const exists = evaluateExists ? evaluateExists({ ...params, ...taskParent.params }, taskParent.code) : false;
      if (!exists) {
        if (_.get(taskParent, "params.company.kind") === "FORMATION") {
          tasks[task].push({
            status: taskParent.status === "IN_PROGRESS" ? 'PENDING' : taskParent.status,
            params: { ...params, ...taskParent.params }
          });
        }
      }
    })
    return tasks;
  }

  const getTaskStartup = useCallback(() => {
    const tasks = {}
    const recompanyCorporateMapTasks = _.get(dataMapAssistance, 'companyCorporateMap.tasks', []);
    _.forEach(recompanyCorporateMapTasks, (task) => {
      if (_.get(task, "isEssential", true)) {
        if (!tasks[task.code]) {
          tasks[task.code] = []
        }
        tasks[task.code].push({
          status: task.status,
          params: _.get(task, "additionalParams.0", {})
        });
      }
    });
    addParentTask(tasks, recompanyCorporateMapTasks, Constants.TASKS.TASK_ADD_COMPANIES_INCORPORATED, {})
    addParentTask(tasks, recompanyCorporateMapTasks, Constants.TASKS.TASK_ADD_COMPANY_FORMATION, {})
    return tasks;
  }, [dataMapAssistance])

  const getTaskGroupCompaniesFormation = useCallback(() => {
    const tasks = {};
    const companies = _.get(dataMapAssistance, 'companyCorporateMap.companies', []);
    _.forEach(companies, (company) => {
      if (company.kind === "FORMATION") {
        const tasksCompany = _.get(company, "tasks", [])
        //Agrega todas las tareas
        _.forEach(tasksCompany, (task) => {
          if (!tasks[task.code]) {
            tasks[task.code] = []
          }
          tasks[task.code].push({
            status: task.status,
            params: { company }
          });
        });
        //Agrega todas las tareas que son necesarias como parent
        addParentTask(tasks, tasksCompany, Constants.TASKS.TASK_ADD_DIRECTORS_FORMATION, { company })
        addParentTask(tasks, tasksCompany, Constants.TASKS.TASK_ADD_SHAREHOLDERS_FORMATION, { company })
        addParentTask(tasks, tasksCompany, Constants.TASKS.TASK_PENDING_GROUP_COMPANY_FORMATION, { company })
        // addParentTask(tasks,tasksCompany, Constants.TASKS.TASK_PENDING_STAKEHOLDER_FORMATION,{company})
      }
    });
    return tasks;
  }, [dataMapAssistance]);

  const getTaskGroupCompaniesIncorporated = useCallback(() => {
    let tasks = {}
    const companies = _.get(dataMapAssistance, 'companyCorporateMap.companies', []);
    _.forEach(companies, (company) => {
      if (company.kind !== "FORMATION") {
        const tasksCompany = _.get(company, "tasks", [])
        //Agrega todas las tareas
        _.forEach(tasksCompany, (task) => {
          if (!tasks[task.code]) {
            tasks[task.code] = []
          }
          tasks[task.code].push({
            status: task.status,
            params: { company }
          });
        });
        //Agrega todas las tareas que son necesarias como parent
        tasks = addParentTask(tasks, tasksCompany, Constants.TASKS.TASK_PENDING_GROUP_COMPANY_INCORPORATED, { company })
        tasks = addParentTask(tasks, tasksCompany, Constants.TASKS.TASK_PENDING_GROUP_COMPANY_INCORPORATED_ACCELERATOR, { company })
        tasks = addParentTask(tasks, tasksCompany, Constants.TASKS.ADGM_RENEWAL_GROUP_COMPANY_TASKS, { company })
        // tasks = addParentTask(tasks, tasksCompany, Constants.TASKS.TASK_PENDING_STAKEHOLDER_INCORPORATED, { company })
      }
      return tasks;
    });
    return tasks;
  }, [dataMapAssistance])

  const getCorporateTasks = useCallback(() => {
    let tasks = {};
    const startupTasks = getTaskStartup();
    const groupCompanyFormationTask = getTaskGroupCompaniesFormation();
    const groupCompanyIncorporatedTask = getTaskGroupCompaniesIncorporated();
    tasks = { ...startupTasks, ...groupCompanyFormationTask, ...groupCompanyIncorporatedTask };
    //Add Stakeholder Tasks
    const stakeholders = _.get(dataMapAssistance, 'companyCorporateMap.stakeholders', []);
    const companies = _.get(dataMapAssistance, 'companyCorporateMap.companies', []);
    _.forEach(stakeholders, (stakeholder) => {
      let tasksStakeholderOriginal = [];
      const normalRoles = _.get(stakeholder, "roles", []);
      const rolesInProgress = _.get(stakeholder, "rolesInProgress.toAdd", []);
      const roles = _.concat(normalRoles, rolesInProgress);
      const companyStakeholderUsed = {}
      _.forEach(roles, (rol) => {
        const id = `${_.get(rol, "entity.id")}_${_.get(stakeholder, "id")}`
        if (!companyStakeholderUsed[id]) {
          companyStakeholderUsed[id] = true;
          if (rol.entity) {
            const companyId = rol.entity?.__typename === "GroupCompany" ? rol?.entity.id : null;
            const stakeholderId = rol.entity?.__typename === "StakeHolder" ? rol?.entity.id : null;
            const companyEntity = _.find(companies, (c) => c.id === companyId);
            const stakeholderEntity = _.find(stakeholders, (c) => c.id === stakeholderId);
            const tasksStakeholder = _.get(stakeholder, "tasks", [])
            _.forEach(tasksStakeholder, (task) => {
              let newTask = { ...task }
              newTask.params = {}
              if (companyEntity) {
                newTask.params.company = companyEntity
              }
              if (stakeholderEntity) {
                newTask.params.stakeholder = stakeholderEntity
              }
              tasksStakeholderOriginal.push(newTask)
            })
          }
        }
      });
      // const tasksStakeholderOriginal = _.get(stakeholder, "tasks", [])
      const warrants = _.map(_.get(stakeholder, 'warrants', []), (rol) => {
        let newRol = { ...rol }
        const companyId = rol.entity?.__typename === "GroupCompany" ? rol?.entity.id : null;
        const stakeholderId = rol.entity?.__typename === "StakeHolder" ? rol?.entity.id : null;
        const companyEntity = _.find(companies, (c) => c.id === companyId);
        const stakeholderEntity = _.find(stakeholders, (c) => c.id === stakeholderId);
        newRol.tasks = _.map(_.get(rol, 'tasks', []), (task) => {
          let newTask = { ...task }
          newTask.params = {}
          if (companyEntity) {
            newTask.params.company = companyEntity
          }
          if (stakeholderEntity) {
            newTask.params.stakeholder = stakeholderEntity
          }
          return newTask
        });
        return newRol;
      })
      const addRoles = _.map(_.get(stakeholder, 'roles', []), (rol) => {
        let newRol = { ...rol }
        const companyId = rol.entity?.__typename === "GroupCompany" ? rol?.entity.id : null;
        const stakeholderId = rol.entity?.__typename === "StakeHolder" ? rol?.entity.id : null;
        if (rol.entity) {
          const companyEntity = _.find(companies, (c) => c.id === companyId);
          const stakeholderEntity = _.find(stakeholders, (c) => c.id === stakeholderId);
          newRol.tasks = _.map(_.get(rol, 'tasks', []), (task) => {
            let newTask = { params: {}, ...task }
            if (companyEntity) {
              newTask.params.company = companyEntity
            }
            if (stakeholderEntity) {
              newTask.params.stakeholder = stakeholderEntity
            }
            return newTask
          });
        }
        return newRol;
      })
      const addFoundersTasks = _.get(stakeholder, "isFounder") ? {
        ...stakeholder,
        tasks: stakeholder.tasks
      } : [];
      const addConvertibles = _.map(_.get(stakeholder, 'convertibles', []), (rol) => {
        let newRol = { ...rol }
        const companyId = rol.entity?.__typename === "GroupCompany" ? rol?.entity.id : null;
        const stakeholderId = rol.entity?.__typename === "StakeHolder" ? rol?.entity.id : null;
        const companyEntity = _.find(companies, (c) => c.id === companyId);
        const stakeholderEntity = _.find(stakeholders, (c) => c.id === stakeholderId);
        newRol.tasks = _.map(_.get(rol, 'tasks', []), (task) => {
          let newTask = { ...task }
          if (companyEntity) {
            newTask.params.company = companyEntity
          }
          if (stakeholderEntity) {
            newTask.params.stakeholder = stakeholderEntity
          }
          return newTask
        });
        return newRol;
      })
      const addEquity = _.map(_.get(stakeholder, 'equity', []), (rol) => {
        let newRol = { ...rol }
        const companyId = rol.entity?.__typename === "GroupCompany" ? rol?.entity.id : null;
        const stakeholderId = rol.entity?.__typename === "StakeHolder" ? rol?.entity.id : null;
        const companyEntity = _.find(companies, (c) => c.id === companyId);
        const stakeholderEntity = _.find(stakeholders, (c) => c.id === stakeholderId);
        newRol.tasks = _.map(_.get(rol, 'tasks', []), (task) => {
          let newTask = { ...task }
          if (companyEntity) {
            newTask.params.company = companyEntity
          }
          if (stakeholderEntity) {
            newTask.params.stakeholder = stakeholderEntity
          }
          return newTask
        });
        return newRol;
      })
      const allTasks = _.concat(
        addRoles,
        addFoundersTasks,
        warrants,
        addConvertibles,
        addEquity,
      )
      let tasksStakeholder = [...tasksStakeholderOriginal];
      _.forEach(allTasks, (listTasks) => {
        const tasksToAdd = _.get(listTasks, "tasks", [])
        tasksStakeholder = [...tasksStakeholder, ...tasksToAdd]
      })
      const evaluateExistsI = ({ stakeholder }) => {
        return !!_.find(tasks[Constants.TASKS.TASK_PENDING_STAKEHOLDER_INCORPORATED], (t) => _.get(t, "params.stakeholder.id") === stakeholder.id)
      }
      const evaluateExistsRenewalIdividual = ({ stakeholder }) => {
        return !!_.find(tasks[Constants.TASKS.ADGM_RENEWAL_TASKS_STAKEHOLDER_INDIVIDUAL], (t) => _.get(t, "params.stakeholder.id") === stakeholder.id)
      }
      const evaluateExistsRenewalADGMIdividual = ({ stakeholder, company }) => {
        const result = _.findIndex(tasks[Constants.TASKS.ADGM_RENEWAL_STAKEHOLDERS_TASKS], (t) => _.get(t, "params.stakeholder.id") === stakeholder.id && _.get(t, "params.company.id") === company.id);
        return result
      } 
      const evaluateExistsRenewalCorporate = ({ stakeholder }) => {
        return !!_.find(tasks[Constants.TASKS.ADGM_RENEWAL_TASKS_STAKEHOLDER_CORPORATE], (t) => _.get(t, "params.stakeholder.id") === stakeholder.id)
      }
      const evaluateExistsIAccelerator = ({ stakeholder }) => {
        return !!_.find(tasks[Constants.TASKS.TASK_PENDING_STAKEHOLDER_INCORPORATED_ACCELERATOR], (t) => _.get(t, "params.stakeholder.id") === stakeholder.id)
      }
      addParentTask(tasks, tasksStakeholder, Constants.TASKS.TASK_PENDING_STAKEHOLDER_INCORPORATED, { company: {}, stakeholder }, evaluateExistsI);
      addParentTask(tasks, tasksStakeholder, Constants.TASKS.ADGM_RENEWAL_TASKS_STAKEHOLDER_INDIVIDUAL, { company: {}, stakeholder }, evaluateExistsRenewalIdividual);
      addParentTask(tasks, tasksStakeholder, Constants.TASKS.ADGM_RENEWAL_TASKS_STAKEHOLDER_CORPORATE, { company: {}, stakeholder }, evaluateExistsRenewalCorporate);
      addAllParents(
        tasks, 
        tasksStakeholder, 
        Constants.TASKS.ADGM_RENEWAL_STAKEHOLDERS_TASKS, 
        { 
          company: {}, 
          stakeholder 
        },
        evaluateExistsRenewalADGMIdividual
      );
      const isCompletedTasks = _.get(tasks, "TASK_ADD_SUBMIT_ACCELERATOR.0.status") === "COMPLETED";
      addParentTaskAccelerator(tasks, tasksStakeholder, Constants.TASKS.TASK_PENDING_STAKEHOLDER_INCORPORATED_ACCELERATOR, { company: {}, stakeholder }, isCompletedTasks, evaluateExistsIAccelerator)
      const evaluateExistsF = ({ stakeholder, company }, task) => {
        const isTaskOnlyADGM = Constants.GROUP_TASKS.TASK_GROUP_FORMATION_ONLY_ADGM_STAKEHOLDER.indexOf(task) >= 0
        if (isTaskOnlyADGM && company.kind === "FORMATION" && company.companyGoal !== "BUY") {
          return true
        }
        return !!_.find(tasks[Constants.TASKS.TASK_PENDING_STAKEHOLDER_FORMATION], (t) => {
          return _.get(t, "params.stakeholder.id") === stakeholder.id && _.get(t, "params.company.id") === company.id
        })
      }
      addAllFormationsParentTask(tasks, tasksStakeholder, Constants.TASKS.TASK_PENDING_STAKEHOLDER_FORMATION, { company: {}, stakeholder }, evaluateExistsF)
    });
    return tasks;
  }, [dataMapAssistance])

  const tasks = useMemo(() => {
    let tasks = {};
    tasks = { ...tasks, ...getCorporateTasks() };
    console.log(tasks)
    return tasks
  }, [getCorporateTasks])

  const stakeholders = useMemo(() => {
    const stakeholders = _.get(dataMapAssistance, 'companyCorporateMap.stakeholders', []);
    return stakeholders
  }, [dataMapAssistance])

  return { tasks, stakeholders, dataMapAssistance, loadingMapTasks };
}

export default useTasks;
