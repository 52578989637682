import { createMachine } from 'xstate';

const machine =
  /** @xstate-layout N4IgpgJg5mDOIC5QFVZgE4AUCuAjANgJYDGASmFIbAC7oCG1hA9gHYB02a6AYk+gLYBiAHIBRABoAVRKAAOTWIUasZIAB6IAjABYAHGwBMAVgBsukye1GjABgDsuzQYA0IAJ5bNATjZGvf3V1tL20TOxt-AF9I11QMHAISckoaemV2AGslXgFBTFJRADVVeUV01Q0EHX1jMwsrWwcnVw8EO01fLxMbG10AZh6m42jYrgSiMgoqWgZmTOy+ITEpEoUlOYrEPr6fTXCvO1s+0J7NFq0DAzYIgKCQsJuRkDisPAnk6bS5thowWT68gVikgQKV1ioQZVtrt9ocbMdujYzu5EHZtNcuj1+oNHMMniwmBA4KoXuMklNUrNWBwuDl+KsyhtIYhtC4UW0fDs7H0DLoutpNDy+iYnqS3uSUjN0mwstQ6QzwSxNggDALDKZdEYdF4Qto+v1zghHGw+dzefzBQZhaKxuLJpKvtTfv8FeVmQg9Vc9hFEQ4ukZdIagmwuTy+ZZLdaYs9bYl7Z8qUqQWC3aBKppA+yALQdbQ2PM2IzaVlWvMWaLRIA */
  createMachine({
    id: 'payManualInvoice',
    initial: 'initialData',
    states: {
      initialData: {
        invoke: {
          src: 'initialData',
          id: 'initialData',
          onDone: [
            {
              target: 'EvaluateFirstStep',
              actions: ['setContextAfterInvoke']
            }
          ],
        }
      },
      EvaluateFirstStep: {
        always: [
          {
            cond: "hasOneStartupAndIsOwner",
            target: 'BlockOneStartupOwnerStep'
          },
          {
            cond: "hasOneStartupAndIsNotOwner",
            target: 'BlockOneStartupViewStep'
          },
          {
            cond: "hasMoreOneStartupAndIsOwner",
            target: 'BlockMoreOneStartupOwnerStep'
          },
          {
            cond: "hasMoreOneStartupAndIsNotOwner",
            target: 'BlockMoreOneStartupViewerStep'
          },
        ]
      },
      BlockMoreOneStartupViewerStep: {
        on: {
          NEXT: {
            target: 'ProductPlanStep',
          }
        }
      },
      BlockMoreOneStartupOwnerStep: {
        on: {
          NEXT: {
            target: 'ProductPlanStep',
          }
        }
      },
      BlockOneStartupOwnerStep: {
        on: {
          NEXT: {
            target: 'ProductPlanStep',
          }
        }
      },
      BlockOneStartupViewStep: {
        on: {
          NEXT: {
            target: 'ProductPlanStep',
          }
        }
      },
      ProductPlanStep: {
        on: {
          NEXT: {
            target: 'SetPayData',
            actions: ['setContextAfterStep']
          },
          PREV: {
            target: 'EvaluateFirstStep',
          }
        }
      },
      SetPayData: {
        on: {
          NEXT: {
            target: 'CheckoutPayment',
            actions: ['setContextAfterStep', "removeErrorCard"]
          },
          PREV: {
            target: 'ProductPlanStep',
          }
        }
      },
      CheckoutPayment: {
        invoke: {
          src: 'CheckoutPayment',
          id: 'CheckoutPayment',
          onDone: [
            {
              target: 'EvaluateErrorCard',
              actions: ['setContextAfterInvoke']
            }
          ],
          onError: [
            {
              target: 'SetPayData'
            }
          ]
        }
      },
      EvaluateErrorCard: {
        always: [
          {
            cond: "isFinishProcess",
            target: 'UpdateStartup'
          },
          {
            cond: 'hasErrorCard',
            target: 'SetPayData',
            actions: ["addErrorCard"]
          },
          {
            cond: 'noHasErrorCard',
            target: 'CheckoutStep',
          },
        ]
      },
      CheckoutStep: {
        on: {
          NEXT: {
            target: 'EvaluateFinishErrorCard',
            actions: ['setContextAfterStep']
          },
          TIMEOUT:{
            target: 'PayTimeOverdue',
          }
        }
      },
      EvaluateFinishErrorCard: {
        always: [{
          cond: 'hasFinishErrorCard',
          target: 'SetPayData',
          actions: ["addErrorCard"]
        },
        {
          cond: 'noHasFinishErrorCard',
          target: 'FinishPayment',
        },
        ]
      },
      FinishPayment: {
        invoke: {
          src: 'FinishPayment',
          id: 'FinishPayment',
          onDone: [
            {
              target: 'UpdateStartup',
              actions: ['setContextAfterInvoke']
            }
          ],
          onError: [
            {
              target: 'SetPayData'
            }
          ]
        }
      },
      UpdateStartup: {
        invoke: {
          src: 'UpdateStartup',
          id: 'UpdateStartup',
          onDone: [
            {
              target: 'PaySuccessStep',
            }
          ],
          onError: [
            {
              target: 'PaySuccessStep'
            }
          ]
        }
      },
      PaySuccessStep: {
        on: {
          NEXT: {
            target: 'finish',
          }
        }
      },
      PayTimeOverdue: {
        on: {
          NEXT: {
            target: 'SetPayData',
          },
          PREV: {
            target: 'ProductPlanStep',
          }
        }
      },
      finish: {
        entry: 'stopMachine',
        type: 'final'
      }
    }
  });

export default machine;