import { default as React, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import * as Yup from "yup";
import Form from "src/components/forms/Form/Form";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import Typography from "src/modules/generic/components/Texts/Typography";
import Button from "src/modules/generic/components/Buttons/Button";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import {
  addAssistantText,
  clearAssistatText,
} from "src/modules/generic/store/action";
import CheckboxButtonSimple from "src/modules/generic/components/Inputs/CheckboxButtonSimple";
import classes from "./classes.module.scss";

function AssignOtherSignatoriesStep() {
  const { context, cancel, next, prev } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return context?.AssignOtherSignatoriesStep;
  }, [context]);

  const claraNomineeUse = context?.ClaraNomineeStep?.useNominee === "true" || context?.ClaraNomineeStep?.useNominee === true 
  const signatories = context?.initialData?.stakeholdersNotUAEOrGCC;

  const stakeholders =_.map(signatories, (signatory) => ({
      value: signatory,
      label: signatory?.fullName,
    }));

  const handleCancel = () => {
    cancel();
  };

  const handleSubmit = async (values) => {
    next(values);
  };

  const handlePrev = () => prev();

  useEffect(() => {
    if(claraNomineeUse)dispatch(
      addAssistantText(
        translate(
          "MODULES.RENEWALS_ADGM.REVIEW_ADGM_AUTHORISED_SIGNATORY.ASSIGN_OTHER_STEP.TOOLTIP"
        )
      )
    );
    return () => {
      dispatch(clearAssistatText());
    };
  }, [dispatch]);

  const schema = Yup.object().shape({
    authorisedSignatory: Yup.object().nullable().required("Required"),
  });

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      <TemplateWizard
        props={{
          title: claraNomineeUse? translate(
            "MODULES.RENEWALS_ADGM.REVIEW_ADGM_AUTHORISED_SIGNATORY.ASSIGN_OTHER_STEP.TITLE"
          ):'Select additional ADGM authorised signatories',
          skeleton: null,
          subTitle: null,
          variant: "simple",
        }}
        buttons={{
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              {translate("BUTTON_CANCEL")}
            </Button>
          ),
          submit: <SubmitButton>Next</SubmitButton>,
          previous: (
            <Button onClick={handlePrev} variant="secondary">
              Previous
            </Button>
          ),
        }}
      >
        {claraNomineeUse && <Typography component="h5">
          To use Clara's service, you must appoint a second Authorised
          Signatory. This person doesn't need to be a GCC Resident or National,
          they only need to have entered the UAE in the last two years. Who will
          you be appointing in this role?
        </Typography>}
        {stakeholders && stakeholders.length > 0?<ControllerInput
          name="authorisedSignatory"
          render={CheckboxButtonSimple}
          options={stakeholders}
          classNameButton={classes.buttonCheckBox}
        />:<Typography component="h5" fontWeigth={'bold'}>
          None
            </Typography>}
      </TemplateWizard>
    </Form>
  );
}

export default AssignOtherSignatoriesStep;
