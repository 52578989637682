import * as Yup from 'yup';

const getSchema = (zipCodeValidation) => {
	return Yup.object().shape({
		card: Yup.object().nullable().when(['cardSelect'], {
			is: null,
			then: Yup.object().nullable().shape({
				number: Yup.string().required('Required').test('number', 'We only accept Visa and Mastercard.', (creditCard) => {
					try {
						const cardInstitutionIssued = parseInt(creditCard.substring(0, 4));
						if ((cardInstitutionIssued >= 5000 && cardInstitutionIssued <= 5599) ||
							cardInstitutionIssued >= 2221 && cardInstitutionIssued <= 2720) {
							return true
						}

						return cardInstitutionIssued >= 4000 && cardInstitutionIssued <= 4900;

					} catch (e) {
						return false
					}
				}),
				date: Yup.string().required('Required'),
				cvc: Yup.string().required('Required'),
			}),
			otherwise: Yup.object().nullable()
		}),
		holderName: Yup.string().nullable().when(['cardSelect'], {
		 is: null,
		 then: Yup.string().nullable().optional(),
		 otherwise: Yup.string().nullable().required('Required'),
		}),
		country: Yup.string().nullable().required('Required'),
		address1: Yup.string().nullable().required('Required'),
		address2: Yup.string().nullable(),
		city: Yup.string().nullable().required('Required'),
		state: Yup.string().nullable(),
		zipCode: Yup.string().test('zipCode', 'Required', zipCodeValidation)
	})
};

export default getSchema
