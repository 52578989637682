import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import Form from "src/components/forms/Form/Form";
import LinkButton from 'src/components/generic/Buttons/LinkButton/LinkButton';
import TemplateWizard from "src/modules//generic/templates/Modal/TemplateWizard";
import PaymentFormContent from 'src/modules/checkout/forms/Payment/PaymentFormContent';
import usePaymentMethodStripe from 'src/modules/checkout/forms/Payment/usePaymentMethods/usePaymentMethodStripe';
import schema from "src/modules/checkout/schemas/payment";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import Typography from "src/modules/generic/components/Texts/Typography";
import useFormat from 'src/modules/generic/hooks/useFormat';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import Button from "../../../../generic/components/Buttons/Button";
import useMachine from '../../../../generic/context/MachineContext/useMachine';
import StripeElementKey from 'src/modules/checkout/components/StripeElementKey';
import useUpsertFormationCompany from "src/modules/startup/GroupCompany/graphql/mutations/useUpsertFormationCompany";
import useSetPrimaryContact from 'src/modules/startup/GroupCompany/graphql/mutations/useSetPrimaryContact';
import useSession from 'src/modules/session/hooks/useSession';
import { Constants } from "src/v1/utils/constants";
import { debug } from 'webpack';

/**
 * Step to set pay data for manual invoice
 * @param {*} param0
 * @returns
 */
function PaymentFormStepChildren({ openParam }) {

  const { next, context, prev, cancel, setAction } = useMachine();
  const { translate } = useTranslate();
  const { moneyFormat } = useFormat();

  const productSelected = context?.getProduct?.product;
  const jurisdictionType = context?.initialData?.company?.jurisdictionType;

  const handlePrev = (values) => {

    prev();
  }
  const handleCancel = (values) => {

    cancel();
  }
  const [card, setCard] = useState(null);

  const { paymentFull } = usePaymentMethodStripe({});
  const { startupId } = useSession();
  const [errorMessageCard, setErrorMessageCard] = useState(null);
  const [upsertFormationCompany] = useUpsertFormationCompany(
    { showSuccessNotification: false, shouldRefetchQuery: ()=>false},
    {}
  );

  const [setPrimaryContact] = useSetPrimaryContact(
    { showSuccessNotification: false , shouldRefetchQuery: ()=>false},
    {}
  );
  const isPincas = (context) => {
    switch (context?.initialData?.productCode) {
      case Constants.MODULES.CHECKOUT.PRODUCTS.CAYMAN_SHOPPING_CART:
      case Constants.MODULES.CHECKOUT.PRODUCTS.ADGM_SHOPPING_CART:
      case Constants.MODULES.CHECKOUT.PRODUCTS.DELAWARE_SHOPPING_CART:
        return true;
      default:
        return false;
    }
  };
  const handleCompleteSubmit = async (_values) => {
    try {
      

      if (context?.ChooseAPayeeFormStep?.authorisedSignatories) {
        if (isPincas(context)) {

          const variables = {
            startupId,
            groupCompanyId: context?.initialData?.groupCompanyId,
            primaryContactId:
              context?.ChooseAPayeeFormStep?.authorisedSignatories,
          }
          await setPrimaryContact({ variables });
        } else {
          const formationData = {
            primaryContact:
              context?.ChooseAPayeeFormStep?.authorisedSignatories,
          };
          const variables = {
            startupId,
            groupCompanyId: context?.initialData?.groupCompanyId,
            groupCompanyData: { formationData },
          };
          console.log("****** upsertFormationCompany variables", variables);

          await upsertFormationCompany({ variables });
        }
      }

      const { cardSelect, ...values } = _values;
      const source = context?.getSource?.source;
      const couponCode = context?.ChooseAPayeeFormStep?.coupon;
      const product = context?.getProduct?.product;
      const id = context?.initialData?.groupCompanyId;
      const cardId = cardSelect === -1 ? null : cardSelect;
      await paymentFull(values, { source, couponCode, product, id, cardId });
      const newCard = { ...card, ...values?.card ?? {} };
      next({ ...values, card: newCard })

    } catch (e) {
      setErrorMessageCard(e.message);
      console.error("***** Error paymentWithNewCard", e);
    }
  }

  const handleBankTransfer = (values = {}) => {
    setAction({ action: 'GO_BANK_TRANSFER', payload: values })
  }

  const [isZipCodeRequired, setIsZipCodeRequired] = useState(false);
  const validateZipCode = (zipCode) => {
    if (isZipCodeRequired) {
      return zipCode && zipCode !== ""
    }
    return true;
  };


  const componentTerms = useMemo(() => productSelected?.code?.includes('SCALE') || jurisdictionType === 'DELAWARE' ? null : TermsFormation, [productSelected, jurisdictionType])
  return (
    <Form
      defaultValues={{}}
      schema={schema(validateZipCode)}
      onSubmit={handleCompleteSubmit}

      // ref={refForm}
      optionsForm={{ mode: "onChange" }}
      onlySendDirty={false}
    >
      <TemplateWizard
        props={{
          title: "Payment",
          skeleton: null
        }}
        buttons={{
          previous: <Button variant='secondary' onClick={handlePrev}>{translate('BUTTON_PREVIOUS')}</Button>,
          cancel: <Button onClick={handleCancel} variant="secondary">{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton >{`Pay  ${_.get(context, "ChooseAPayeeFormStep.amount.currency")} ${moneyFormat(_.get(context, "ChooseAPayeeFormStep.amount.amount"))}`}</SubmitButton>,
          // previous: <Button onClick={handlePrev}>Previous</Button>,
        }}
      >
        <div style={{ gridTemplateColumns: "1fr 1fr", gap: "3rem", display: "grid" }}>
          <Typography variant="h2" color={"black"} component={"p"} >
            Card details
          </Typography>
          <Typography variant="h2" color={"black"} component={"p"} >
            Billing address
          </Typography>
        </div>
        <div style={{ gridTemplateColumns: "1fr 1fr", gap: "3rem", display: "grid" }}>
          <PaymentFormContent
            showTitle={false}
            defaultVat={{}}
            useCard={true}
            selectCard={card}
            errorCard={errorMessageCard ? "error" : null}
            errorMessageCard={errorMessageCard}
            setIsZipCodeRequired={setIsZipCodeRequired}
            setCard={setCard}
            enabledVat={false}
            terms={"By confirming your subscription, you allow Clara to charge your card for this payment and future payments in accordance with their terms."}
            termsComponent={componentTerms}
            propsTermsComponent={{ onClickLink: handleBankTransfer }}
          />
        </div>
      </TemplateWizard>
    </Form>


  );
}
function PaymentFormStep({ openParam }) {
  const { context } = useMachine();
  const source = context?.getSource?.source;
  return (
    <StripeElementKey source={source}>
      <PaymentFormStepChildren openParam={openParam} />
    </StripeElementKey>
  )
}
export default PaymentFormStep;

const TermsFormation = ({ terms, onClickLink }) => {
  return <div style={{ position: 'absolute', bottom: '10px', width: '485px' }}>
    <Typography>To pay for this transaction via bank transfer</Typography> <LinkButton onClick={onClickLink}>click here</LinkButton>
    <br /><br />
    <Typography>{terms}</Typography>

  </div>
}