import classnames from "classnames";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import useModal from "src/modules/generic/hooks/useModal";
import statusPending from "../../../../config/statusPending";
import useBusinessRules from '../../../../modules/clara/hooks/useBusinessRules';
import useSession from "../../../session/hooks/useSession";
import MapV3 from "../../components/Map/index";
import classes from "./classes.module.scss";

import PayBlockModal from "src/modules/startup/PayBlockModal";
const MapView = (props) => {

  const { setPageLoaded, startup } = useSession();
  const { openModal } = useModal();
  const { isOwner, isDowngraded } = useBusinessRules();

  const hasPendingCompany = useSelector((store) =>
    statusPending.pendingGroupCompanies(store, null)
  );

  const hasPendingOnboard = useSelector((store) =>
    statusPending.hasPendingOnboard(store, null)
  );

  useEffect(() => {
    setPageLoaded("switchStartup");
  }, []);

  useEffect(() => {
    if (isDowngraded && isDowngraded()) {
      openModal(
        PayBlockModal,
        "MODAL_BLOCKED_USER_IS_DOWNGRADED"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startup]);

  return (
    <React.Fragment>
      <div
        className={classnames(`${classes.ContentMap} ${classes.ContentMapV4}`)}
      >
        <MapV3 empty={hasPendingCompany} openHeader={hasPendingOnboard} />
      </div>
    </React.Fragment>
  );
};

export default MapView;
