import { useMemo } from 'react';
import Button from "../../../../generic/components/Buttons/Button";
import useModal from "../../../../generic/hooks/useModal";
import useTranslate from '../../../../generic/hooks/useTranslate';
import TemplateFormTwoColumnsSimple from 'src/modules/generic/templates/Modal/TemplateFormTwoColumnsSimple';
import { Stack } from "@mui/material";
import Typography from "src/modules/generic/components/Texts/Typography";
import cls from "./classes.module.scss";
import img from "./imgs/Payment-overdue-illustration.svg";
import useSession from 'src/modules/session/hooks/useSession';
import useNavigation from 'src/modules/generic/hooks/useNavigation';
import { Constants } from "src/v1/utils/constants";
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
/**
 * Step success pay overdue
 * @param {*} param0
 * @returns
 */
function BlockMoreOneStartupOwnerStep({ 
  openParam,
}) {
  const {startup} = useSession()
  const {goTo} = useNavigation()
  const { translate } = useTranslate();
  const { closeModal: onClose } = useModal();
  const {next} = useMachine()

  const handleGoToMap = () => {
    goTo(Constants.PAGES.switchStartup)  ;
  }

  const handleNext = () => {
    next();
  }

  const leftComponent = useMemo(() => {
    return (
      <Stack spacing={4}>
        <Typography className={cls["cartTitle"]} weight={"bold"}>
          {translate('MODAL_BLOCK_USER_MORE_ONE_STARTUP_OWNER_TEXT1')}
        </Typography>
        <Typography className={cls["cartSubtitleTitle"]} weight={"bold"}>
          {translate('MODAL_BLOCK_USER_MORE_ONE_STARTUP_OWNER_TEXT2',{startupName:startup?.name})}
        </Typography>
        <Typography variant="h2" color={"black"} component={"p"} >
          {translate('MODAL_BLOCK_USER_MORE_ONE_STARTUP_OWNER_TEXT3')}
        </Typography>
      </Stack>
    )
  }, [])

  const rightComponent = useMemo(() => {
    return (
      <div className={cls["rightContentImage"]}>
        <img src={img} alt="" />
      </div>)
  }, [])

  return (
    <TemplateFormTwoColumnsSimple
      leftComponent={leftComponent}
      rightComponent={rightComponent}
      buttons={{
        previous: <Button variant="secondary" onClick={handleGoToMap}>{translate('BUTTON_PREVIOUS')}</Button>,
        submit: <Button variant="primary" onClick={handleNext}>{translate('BUTTON_NEXT')}</Button>,
      }}
    >
    </TemplateFormTwoColumnsSimple>
  );
}

export default BlockMoreOneStartupOwnerStep;
