import React from "react";
import * as Yup from "yup";
import Form from "../../../../components/forms/Form/Form";
import useTranslate from "../../../../modules/generic/hooks/useTranslate";
import ControllerInput from "../../../../modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import SubmitButton from "../../../../modules/generic/components/Buttons/SubmitButton";
import FormProps from "../../../../modules/generic/forms/interfaces/FormProps";
import TemplateFormDefault from "../../../../modules/generic/templates/Modal";
import CheckboxButtonSimple from '../../../generic/components/Inputs/CheckboxButtonSimple'
type OnChange = (values: any) => void | Promise<void>

const schema = Yup.object().shape({
  alreadyFormed: Yup.boolean().required('This field is required').nullable()
})

interface FormAlreadyFormedProps extends FormProps {
  onChange?: OnChange,
}

const FormAlreadyFormed: React.FC<FormAlreadyFormedProps> = ({
  initialValues = {},
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
  onChange,
}) => {

  const { translate } = useTranslate();
  const handleSubmit = async (values) => {
    onCompleteSubmit && (await onCompleteSubmit(values));
  };

  const options = React.useMemo(() => {
    return [
        { label: translate('MODULES.MAPMODALS.ALREADY_FORMED.FIRST_STEP.NOBTN'), value: false },
        { label: translate('MODULES.MAPMODALS.ALREADY_FORMED.FIRST_STEP.YESBTN'), value: true }]
  }, [])

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
      onlySendDirty={false}
    >
      <Template
        buttons={{
          ...buttons,
          submit: <SubmitButton {...buttonSubmitProps}>{translate('MODULES.MAPMODALS.NEXT')}</SubmitButton>,
        }}
        props={propsTemplate}
      >
        <ControllerInput
          render={CheckboxButtonSimple}
          options={options}
          name='alreadyFormed'
          onChange={onChange}
          defaultlabel={''}
          size={"md"}
        />
      </Template>
    </Form>
  );
};

export default FormAlreadyFormed;
