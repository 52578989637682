// import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import _ from "lodash"
import useCheckoutApi from 'src/modules/checkout/hooks/useCheckoutApi';
import useInit3DSPaymentToUpgradeStartup from 'src/modules/checkout/hooks/useInit3DSPaymentToUpgradeStartup';
import usePayWithExistingCreditCard from 'src/modules/checkout/hooks/usePayWithExistingCreditCard';
import useSession from 'src/modules/session/hooks/useSession';
import useFinish3DSPaymentToUpgradeStartup from 'src/modules/checkout/hooks/useFinish3DSPaymentToUpgradeStartup';
import { useGetStartupAndActionsLazy } from '../../graphql/startup/useGetStartup';
import { start } from 'xstate/lib/actions';
import { useGetProductLazy } from 'src/modules/checkout/hooks/useGetProduct';
// import useUpsertDocument from 'src/modules/startup/graphql/document/mutation/useUpsertDocument'
// import useSession from "../../../../session/hooks/useSession";
import { Constants } from 'src/v1/utils';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';

const STATUS = {
  success: "success",
  error: "error",
  process: "process",
  input: "input",
};

const useServices = ({ source = "STARTUP" }) => {
  const { startupId: id, updateStartup, startup, session } = useSession();
  const { productCode } = useMemo(() => {
    const activeSubscriptionIndex = (startup?.subscriptions?.length ?? 0) - 1;
    let productCode = ""
    if (activeSubscriptionIndex >= 0) {
      const productCode = _.get(startup, `subscriptions[${activeSubscriptionIndex}].productCode`, "")
      return { productCode }
    }
    return { productCode }
  }, [startup])


  // const { mutation: updateDocument } = useUpsertDocument();
  const { manualQuery: getProductMonthly } = useGetProductLazy({ productCode: Constants.SUBSCRIPTIONS_TYPES.MONTHLY })
  const { manualQuery: getProductAnnual } = useGetProductLazy({ productCode: Constants.SUBSCRIPTIONS_TYPES.ANNUAL })

  const { manualQuery: getStartup } = useGetStartupAndActionsLazy();
  const [paymentSave] = useInit3DSPaymentToUpgradeStartup({ showSuccessNotification: false, showErrorNotification: false, shouldRefetchQuery: () => true });
  const [paymentCardSave] = usePayWithExistingCreditCard({ showSuccessNotification: false, showErrorNotification: false, shouldRefetchQuery: () => true });
  const [finishPayment] = useFinish3DSPaymentToUpgradeStartup({
    showSuccessNotification: false,
    showErrorNotification: false,
  });
  const { keys: checkoutSource } = useCheckoutApi(source)
  const getCheckoutToken = useCallback(async (values, card) => {
    const paramsCheckout = {
      name: _.get(values, "holderName", ""),
      cvv: _.get(values, "card.cvc", ""),
      expiry_month: parseInt(_.get(values, "card.date", "/").split("/")[0]),
      expiry_year: parseInt("20" + _.get(values, "card.date", "/").split("/")[1]),
      number: _.get(values, "card.number", "").replace(/\s+/g, ''),
      phone: {},
      requestSource: "JS",
      type: "card"
    };


    const options = {
      headers: {
        Authorization: _.get(checkoutSource, `token`),
        Accept: "*/*",
        "Content-Type": 'application/json'
      },
      method: "POST",
      body: JSON.stringify(paramsCheckout)
    };

    const api = _.get(checkoutSource, `api`)

    const responseCheckOut = await fetch(api, options);


    if (responseCheckOut.ok) {
      const json = await responseCheckOut.json();
      return json;
    } else {
      throw new Error()
    }



  }, [checkoutSource]);
  const submitNewCard = useCallback(async (values, context) => {
    try {

      const checkout = await getCheckoutToken(values);
      const paymentValues = {
        creditCardToken: checkout.token,
        "billingAddress": {
          "country": _.get(values, "country"),
          "addressLine1": _.get(values, "address1"),
          "addressLine2": _.get(values, "address2"),
          "city": _.get(values, "city"),
          "state": _.get(values, "state"),
          "zipCode": _.get(values, "zipCode"),
          "onBehalfOfAVATRegisteredCompany": undefined
        },
        cardHolderName: _.get(values, "holderName"),
        "couponCode": context?.ProductPlanStep?.coupon,
        productCode: context?.ProductPlanStep?.productCode,
        relatedItemId: id,
        source: source
      };

      if (_.get(values, "onBehalfOfAVATRegisteredCompany")) {
        paymentValues.billingAddress.onBehalfOfAVATRegisteredCompany = _.get(values, "onBehalfOfAVATRegisteredCompany")
      }

      const result = await paymentSave(paymentValues);

      if (!!result) {
        const url = _.get(result, "data.initNewCreditCardPayment");
        return {
          url: url,
          status: STATUS.success
        }
      } else {
        return {
          url: null,
          status: STATUS.error
        }
      }
    } catch (e) {

      return {
        url: null,
        status: STATUS.error
      }
    }

  }, [getCheckoutToken, id, paymentSave, source]);

  const submitOldCard = useCallback(async ({ card, ...values }, context) => {
    try {
      const paymentValues = {
        "billingAddress": {
          "country": _.get(values, "country"),
          "addressLine1": _.get(values, "address1"),
          "addressLine2": _.get(values, "address2"),
          "city": _.get(values, "city"),
          "state": _.get(values, "state"),
          "zipCode": _.get(values, "zipCode"),
          "onBehalfOfAVATRegisteredCompany": undefined
        },
        // cardHolderName: _.get(values, "holderName"),
        "creditCardId": _.get(card, "_id"),
        "couponCode": context?.ProductPlanStep?.coupon,
        productCode: context?.ProductPlanStep?.productCode,
        relatedItemId: id,
        source: source
      };
      if (_.get(values, "onBehalfOfAVATRegisteredCompany")) {
        paymentValues.billingAddress.onBehalfOfAVATRegisteredCompany = _.get(values, "onBehalfOfAVATRegisteredCompany")
      }

      const result = await paymentCardSave(paymentValues);
      if (result) {
        return {
          url: null,
          status: STATUS.success
        }
      } else {
        return {
          url: null,
          status: STATUS.error
        }
      }

    } catch (e) {
      return {
        url: null,
        status: STATUS.error
      }
    }

  }, [id, paymentCardSave, source]);
  return useMemo(() => ({

    initialData: async (context, event) => {
      const moreOneStartup = session?.moreOneStartup ?? false;
      const productRequestMonthly = await getProductMonthly();
      const productRequestAnnual = await getProductAnnual();
      let productRequest = productRequestMonthly;
      if (productCode === Constants.SUBSCRIPTIONS_TYPES.ANNUAL) {
        productRequest = productRequestAnnual;
      }
      let amount = productRequest?.data?.getProduct?.value
      const currency = productRequest?.data?.getProduct?.currency
      const vatPercent = productRequest?.data?.getProduct?.defaultVAT?.value
      amount = amount + (amount * vatPercent / 100);
      const result = {
        product: productRequest?.data?.getProduct,
        productMonthly: productRequestMonthly?.data?.getProduct,
        productAnnual: productRequestAnnual?.data?.getProduct,
        productCode,
        source,
        id: start,
        amount,
        currency,
        moreOneStartup
      }
      return result;
    },

    CheckoutPayment: async (context, event) => {
      const { ...values } = context?.SetPayData;
      let result = {};
      const cardSelect = values?.cardSelect ?? -1;

      if (cardSelect === -1) {
        result = await submitNewCard(values, context);
      } else {
        result = await submitOldCard(values, context);
      }

      const status = result?.status;
      const url = result?.url;
      if (status === "error") {
        return {
          errorCard: true,
          finishProcess: false
        }
      }
      if (status === "success" && !url) {
        return {
          finishProcess: true
        }
      }
      return { ...result, errorCard: false, finishProcess: false };


    },
    FinishPayment: async (context, event) => {

      const ckoSessionId = context?.CheckoutStep?.ckoSessionId

      let result = {};
      try {
        result = await finishPayment({ ckoSessionId, paymentSource: source, relatedItemId: id, startupId: id })

      } catch (e) {
        console.log("*** FinishPayment error", e)
      }

      return {
        ...result
      }
    },
    UpdateStartup: async (context, event) => {

      const startupData = await getStartup({ variables: { startupId: id } });
      await updateStartup(startupData?.data?.getStartup)

      return {}
    }

  }), [submitNewCard, submitOldCard]);
}

export default useServices;