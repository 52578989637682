import { useMemo } from 'react';
import useBusinessRules from 'src/modules/clara/hooks/useBusinessRules';
import useSession from 'src/modules/session/hooks/useSession';
// import _ from "lodash";

const useGuards = () => {
  const {isOwner} = useBusinessRules()
  const { session} = useSession()
  return useMemo(() => ({
    hasOneStartupAndIsOwner: (context) => {
        return isOwner() && !session?.moreOneStartup
    },
    hasOneStartupAndIsNotOwner: (context) => {
      return !isOwner() && !session?.moreOneStartup
    },
    hasMoreOneStartupAndIsOwner: (context) => {
      return isOwner() && session?.moreOneStartup
    },
    hasMoreOneStartupAndIsNotOwner: (context) => {
      return !isOwner() && session?.moreOneStartup
    },
    isFinishProcess: (context) => {

      return context?.CheckoutPayment?.finishProcess;
    },
    hasErrorCard: (context) => {
      
      return context?.CheckoutPayment?.errorCard;
    },
    noHasErrorCard: (context) => {
      
      return !context?.CheckoutPayment?.errorCard;
    },
    hasFinishErrorCard: (context) => {
      
      return context?.CheckoutStep?.status==="error";
    },
    noHasFinishErrorCard: (context) => {
      
      return context?.CheckoutStep?.status!=="error";
    }
    
  }), []);
}

export default useGuards;