import React, { useMemo, useState } from "react";
import _ from "lodash";
import classesModule from "./SubscriptionsView.module.scss";
import TypographyLocal from "../../../../../modules/generic/components/Texts/Typography";
import SquareBlock from "../../../../../modules/checkout/components/SquareBlock";
import SquareContent from "../../../../../modules/checkout/components/SquareContent";
import SubscriptionDetails from "../../../../../modules/checkout/components/SubscriptionDetails";
import Button from "../../../../../modules/generic/components/Buttons/Button";
import useBusinessRules from "../../../../../modules/clara/hooks/useBusinessRules";
import useTranslate from "../../../../../modules/generic/hooks/useTranslate";
import { useSelector, useDispatch } from "react-redux";
import { Constants } from "../../../../../v1/utils";
import moment from "moment";
import visa from "../../../../../images/visa.svg";
import masterCard from "../../../../../images/mastercard.svg";
import useGetProduct, {
  useGetProductLazy,
} from "../../../../../modules/checkout/hooks/useGetProduct";
import useModal from "../../../../../modules/generic/hooks/useModal";
import EditRenewalAndBilling from "../../../../../modules/checkout/modals/RenewalAndBilling";
import ChangeCard from "../../../../../modules/checkout/modals/ChangeCard";
import useSession from "../../../../../modules/session/hooks/useSession";
import { helper } from "../../../../../v1/utils";
import PayOverdueModal from "src/modules/startup/PayOverdueModal";

const Subscriptions = () => {
  const {
    isActivePlan,
    isAnualPlan,
    isMonthlyPlan,
    isInactivePlan,
  } = useBusinessRules();

  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const { openModal, closeModal } = useModal();
  const session = useSelector((state) => _.get(state, "session"));
  const activeSubscriptionData = _.get(
    session,
    "startup.activeSubscriptionPlan.activeSubscriptionData",
    {}
  );

  const isInGracePeriod = _.get(
    activeSubscriptionData,
    "mustPayInvoice",
    false
  );
  const { startup } = useSession();
  const [nextSubscription, setNextSubscription] = useState(null);

  const { data: productData } = useGetProduct({
    productCode: _.get(
      activeSubscriptionData,
      "productCode",
      "CARD_VERIFICATION"
    ),
  });

  const {
    manualQuery: manualNextProductData,
    data: nextProductData,
  } = useGetProductLazy({
    productCode: _.get(nextSubscription, "productCode", "CARD_VERIFICATION"),
  });

  const handleSubscriptionType = () => {
    if (isInactivePlan()) {
      return translate("MODULES.CHECKOUT.START_PLAN");
    }
    if (isActivePlan()) {
      return translate("MODULES.CHECKOUT.SCALE_PLAN");
    }
  };

  const handleSubscriptionTypeSubtitle = () => {
    if (isInactivePlan()) {
      return translate("MODULES.CHECKOUT.START_PLAN_SUBTITLE");
    }
    if (isActivePlan()) {
      return translate("MODULES.CHECKOUT.MONTHLY_12_SUBSCRIPTIONS");
    }
  };

  const handleSubscriptionsFirstCard = () => {
    if (isAnualPlan()) {
      return translate("MODULES.CHECKOUT.SCALE_PLAN_ANNUAL");
    }
    if (isMonthlyPlan()) {
      return translate("MODULES.CHECKOUT.SCALE_PLAN_MONTHLY");
    }
  };

  const getFeatureByProductCode = (store, productCode) => {
    const feature = _.find(
      _.get(store, `session.startup.activeSubscriptionPlan.features`, []),
      (feature) => feature.code === productCode
    );
    if (!feature) return null;
    const limit = _.get(feature, "limit.amount", 0);
    const usages = _.get(store, "session.startup.subscriptionFeaturesUsage");
    const usage = _.find(usages, (feature) => feature.code === productCode);
    const actual = usage ? usage.amount : 0;
    return `${actual}/${limit}`;
  };

  const countHealtCheck = useSelector((store) => {
    return getFeatureByProductCode(store, Constants.PRODUCTS.HEALTH_CHECK);
  });

  const countProfileShares = useSelector((store) => {
    return getFeatureByProductCode(store, Constants.PRODUCTS.SHARE_PROFILE);
  });

  const countDocumentsUploaded = useSelector((store) => {
    return getFeatureByProductCode(store, Constants.PRODUCTS.DOCUMENTS);
  });

  const countDocumentsGenerated = useSelector((store) => {
    return getFeatureByProductCode(
      store,
      Constants.PRODUCTS.GENERATE_DOCUMENTS
    );
  });

  const handleCardExpirationDate = () => {
    let exMoth = _.get(activeSubscriptionData, "bankCard.expiryMonth", "");
    if (exMoth < 10) {
      exMoth = `0${exMoth}`;
    }
    return `${exMoth}/${_.get(
      activeSubscriptionData,
      "bankCard.expiryYear",
      ""
    )}`;
  };

  const {
    billingCycleSecond,
    nextPaymentDueBlackLine,
    nextPaymentDueSecondLine,
    bankCard,
  } = useMemo(() => {
    const format = helper.getFormatByKey(Constants.TYPE_FORMATS.DATE_SHOW);

    //Billings cycle
    let billingCycleSecond = "";

    //Next payment due
    let nextPaymentDueBlackLine = "";
    let nextPaymentDueSecondLine = "";

    let money = _.get(
      productData,
      "getProduct.currency",
      translate("MODULES.CHECKOUT.DEFAULT_MONEY")
    );
    let paid = _.get(productData, "getProduct.installmentValue", "");

    if (!_.get(activeSubscriptionData, "cancelAutoRenewal")) {
      const nextPendingSubscription = _.get(
        startup,
        `subscriptions[${_.findIndex(startup.subscriptions, (subscription) => {
          return (
            _.get(subscription, "status") === Constants.STATUS_PLANS.PENDING
          );
        })}]`
      );
      setNextSubscription(nextPendingSubscription);
      manualNextProductData();
      let nextBillingCycleProductCode = _.get(
        nextPendingSubscription,
        "productCode"
      );

      if (
        _.get(activeSubscriptionData, "productCode") !==
        nextBillingCycleProductCode
      ) {
        const renewalDate = moment(
          _.get(activeSubscriptionData, "renewalDate"),
          ""
        ).format(format);
        if (
          nextBillingCycleProductCode === Constants.SUBSCRIPTIONS_TYPES.ANNUAL
        ) {
          billingCycleSecond = translate(
            "MODULES.CHECKOUT.SWITCH_MONTHLY_TO_ANNUAL",
            { renewalDate: renewalDate }
          );
        } else if (
          nextBillingCycleProductCode === Constants.SUBSCRIPTIONS_TYPES.MONTHLY
        ) {
          billingCycleSecond = translate(
            "MODULES.CHECKOUT.SWITCH_ANNUAL_TO_MONTHLY",
            { renewalDate: renewalDate }
          );
        }
      }

      if (nextPendingSubscription) {
        if (
          _.get(activeSubscriptionData, "productCode") ===
          Constants.SUBSCRIPTIONS_TYPES.MONTHLY
        ) {
          if (parseInt(_.get(activeSubscriptionData, "pendingMonths")) === 0) {
            money = _.get(
              nextProductData,
              "getProduct.currency",
              translate("MODULES.CHECKOUT.DEFAULT_MONEY")
            );
            paid = _.get(nextProductData, "getProduct.installmentValue", "");
          }
        } else if (
          _.get(activeSubscriptionData, "productCode") ===
          Constants.SUBSCRIPTIONS_TYPES.ANNUAL
        ) {
          money = _.get(
            nextProductData,
            "getProduct.currency",
            translate("MODULES.CHECKOUT.DEFAULT_MONEY")
          );
          paid = _.get(nextProductData, "getProduct.installmentValue", "");
        }
      }

      nextPaymentDueBlackLine = translate("MODULES.CHECKOUT.PAYMENT_DATA", {
        money: money,
        paid: paid,
        vat: translate("MODULES.CHECKOUT.USE_VAT"),
      });

      nextPaymentDueSecondLine = translate("MODULES.CHECKOUT.AUTO_PAYMENT", {
        date: moment(
          _.get(activeSubscriptionData, "autoPaymentScheduleDate", "")
        ).format("DD MMM YYYY"),
      });
    } else {
      if (isAnualPlan()) {
        nextPaymentDueBlackLine = "-";
      } else {
        if (parseInt(_.get(activeSubscriptionData, "pendingMonths")) === 0) {
          nextPaymentDueBlackLine = "-";
        } else {
          nextPaymentDueBlackLine = translate("MODULES.CHECKOUT.PAYMENT_DATA", {
            money: money,
            paid: paid,
            vat: translate("MODULES.CHECKOUT.USE_VAT"),
          });
        }
      }

      if (
        _.get(activeSubscriptionData, "status") ==
        Constants.STATUS_PLANS.INACTIVE
      ) {
        nextPaymentDueSecondLine = translate(
          "MODULES.CHECKOUT.AUTO_RENEWAL_OFF"
        );
      } else {
        if (isAnualPlan()) {
          nextPaymentDueSecondLine = translate(
            "MODULES.CHECKOUT.SUBSCRIPTION_EXPIRATION",
            {
              renewalDate: moment(
                _.get(
                  startup.activeSubscriptionPlan.activeSubscriptionData,
                  "renewalDate"
                )
              ).format(format),
            }
          );
        } else {
          if (parseInt(_.get(activeSubscriptionData, "pendingMonths")) === 0) {
            nextPaymentDueSecondLine = "";
            if (
              moment(
                _.get(
                  startup.activeSubscriptionPlan.activeSubscriptionData,
                  "renewalDate"
                )
              ) > moment()
            ) {
              billingCycleSecond = translate(
                "MODULES.CHECKOUT.SUBSCRIPTION_EXPIRATION",
                {
                  renewalDate: moment(
                    _.get(
                      startup.activeSubscriptionPlan.activeSubscriptionData,
                      "renewalDate"
                    )
                  ).format(format),
                }
              );
            }
          } else {
            nextPaymentDueSecondLine = translate(
              "MODULES.CHECKOUT.AUTO_PAYMENT",
              {
                date: moment(
                  _.get(activeSubscriptionData, "autoPaymentScheduleDate", "")
                ).format("DD MMM YYYY"),
              }
            );
          }
        }
      }
    }

    const bankCard = _.get(activeSubscriptionData, "bankCard");

    return {
      billingCycleSecond,
      nextPaymentDueBlackLine,
      nextPaymentDueSecondLine,
      bankCard,
    };
  }, [startup, productData, nextProductData]);

  return (
    <div className={` ${classesModule.Subscriptions} `}>
      <div className={` ${classesModule.title} `}>
        <TypographyLocal
          className={` ${classesModule.titleColor} `}
          variant="h2"
        >
          {translate("MODULES.CHECKOUT.PAGE_TITLE")}
        </TypographyLocal>
        {isActivePlan() && (
          <div className={` ${classesModule.buttons} `}>
            <Button
              variant="card"
              onClick={() => {
                openModal(EditRenewalAndBilling, {
                  initialValues: productData,
                });
              }}
            >
              {translate("MODULES.CHECKOUT.BUTTON_STARTUP_EDIT_SUBSCRIPTION")}
            </Button>
            {!isInGracePeriod && (
              <Button
                variant="card"
                onClick={() => {
                  openModal(ChangeCard, { session: { tema: "tema", session } });
                }}
              >
                {translate("MODULES.CHECKOUT.BUTTON_STARTUP_UPDATE_CARD")}
              </Button>
            )}
            {isInGracePeriod && (
              <Button
                variant="card"
                onClick={() => {
                  openModal(PayOverdueModal, {});
                }}
              >
                {translate("MODULES.CHECKOUT.BUTTON_STARTUP_UPDATE_CARD")}
              </Button>
            )}
          </div>
        )}
      </div>

      <div className={` ${classesModule.subtitle} `}>
        <TypographyLocal className={` ${classesModule.intro} `}>
          {translate("MODULES.CHECKOUT.YOUR_PLAN")}
        </TypographyLocal>
        <TypographyLocal
          className={` ${
            isActivePlan()
              ? classesModule.typePlanResalt
              : classesModule.typePlan
          } `}
        >
          {handleSubscriptionType()}
        </TypographyLocal>
      </div>
      <div className={` ${classesModule.subtitleType} `}>
        <TypographyLocal>{handleSubscriptionTypeSubtitle()}</TypographyLocal>
      </div>
      <div className={` ${classesModule.squeareContainer} `}>
        {isInactivePlan() && (
          <>
            <SquareBlock>
              <SquareContent
                icon={"Generic"}
                firstLine={translate("MODULES.CHECKOUT.DOCUMENTS_GENERATED")}
                blackLine={countDocumentsGenerated}
              />
            </SquareBlock>
            <SquareBlock>
              <SquareContent
                icon={"Upload-doc"}
                firstLine={translate("MODULES.CHECKOUT.DOCUMENTS_UPLOADED")}
                blackLine={countDocumentsUploaded}
              />
            </SquareBlock>
            {/* <SquareBlock>
              <SquareContent
                icon={"Profile-share"}
                firstLine={translate("MODULES.CHECKOUT.PROFILE_SHARES")}
                blackLine={countProfileShares}
              />
            </SquareBlock> */}
            <SquareBlock>
              <SquareContent
                icon={"Heart-lcon"}
                firstLine={translate("MODULES.CHECKOUT.HEALTH_CHECK")}
                blackLine={countHealtCheck}
              />
            </SquareBlock>
          </>
        )}
        {isActivePlan() && (
          <>
            <SquareBlock>
              <SquareContent
                icon={"Invoice"}
                firstLine={translate("MODULES.CHECKOUT.BILLING_CYCLE")}
                blackLine={handleSubscriptionsFirstCard()}
                secondLine={billingCycleSecond}
              />
            </SquareBlock>
            <SquareBlock payMode={isInGracePeriod}>
              {isInGracePeriod ? (
                <SquareContent
                  payMode={true}
                  icon={"Dollar"}
                  firstLine={translate("MODULES.CHECKOUT.LAST_PAYMENT")}
                  blackLine={translate("MODULES.CHECKOUT.PAYMENT_DATA", {
                    money: _.get(
                      productData,
                      "getProduct.currency",
                      translate("MODULES.CHECKOUT.DEFAULT_MONEY")
                    ),
                    paid: _.get(productData, "getProduct.installmentValue", ""),
                    vat: translate("MODULES.CHECKOUT.USE_VAT"),
                  })}
                  secondLine={translate(
                    "MODULES.CHECKOUT.PAYMENT_OVERDUE_SECOND",
                    {
                      date: moment(
                        _.get(activeSubscriptionData, "renewalDate", "")
                      ).format("DD MMM YYYY"),
                    }
                  )}
                  btnVariant={"pay"}
                />
              ) : (
                <SquareContent
                  icon={"Dollar"}
                  firstLine={translate("MODULES.CHECKOUT.NEXT_PAYMENT")}
                  blackLine={nextPaymentDueBlackLine}
                  secondLine={nextPaymentDueSecondLine}
                />
              )}
            </SquareBlock>
            <SquareBlock>
              <SquareContent
                icon={"Clock1"}
                firstLine={translate("MODULES.CHECKOUT.AUTO_RENEWAL_DATE")}
                blackLine={
                  !_.get(
                    session,
                    "startup.activeSubscriptionPlan.activeSubscriptionData.cancelAutoRenewal"
                  )
                    ? moment(
                        _.get(activeSubscriptionData, "renewalDate", "")
                      ).format("DD MMM YYYY")
                    : "Off"
                }
              />
            </SquareBlock>
            <SquareBlock>
              <SquareContent
                image={
                  _.get(bankCard, "bankCardType", "") === "VISA"
                    ? visa
                    : masterCard
                }
                firstLine={translate("MODULES.CHECKOUT.PAYMENT_METHOD")}
                btnVariant={"primary"}
                paymentLine={translate("MODULES.CHECKOUT.CARD_DATA", {
                  last: _.get(bankCard, "last4", ""),
                  exp: handleCardExpirationDate(),
                })}
              />
            </SquareBlock>
          </>
        )}
      </div>
      <div className={` ${classesModule.detailsContainer} `}>
        {isInactivePlan() && (
          <>
            <SubscriptionDetails
              saveUpTitle={translate("MODULES.CHECKOUT.SAVE_TITLE")}
              saveUpSubtitle={translate("MODULES.CHECKOUT.SAVE_SUBTITLE")}
              detailTitle={translate("MODULES.CHECKOUT.DETAILS_TITLE")}
              detailSubtitle={translate("MODULES.CHECKOUT.DETAILS_SUBTITLE")}
              scaleItemList={[
                {
                  icon: "document-stack",
                  title: translate("MODULES.CHECKOUT.DETAILS_ITEM_GD"),
                },
                {
                  icon: "Document",
                  title: translate("MODULES.CHECKOUT.DETAILS_ITEM_FA"),
                },
                {
                  icon: "Document-edit",
                  title: translate("MODULES.CHECKOUT.DETAILS_ITEM_DU"),
                },
                {
                  icon: "Concierge",
                  title: translate("MODULES.CHECKOUT.DETAILS_ITEM_CO"),
                },
                {
                  icon: "Heart",
                  title: translate("MODULES.CHECKOUT.DETAILS_ITEM_HC"),
                },
                {
                  icon: " ",
                  title: translate(" "),
                },
                {
                  icon: "Legal",
                  title: translate("MODULES.CHECKOUT.DETAILS_ITEM_SIP"),
                },
              ]}
            >
              <Button
                className={classesModule.buttonBenefits}
                variant="upgrade"
                onClick={() => {
                  dispatch({
                    type: "GO_TO",
                    page: Constants.PAGES.upgrade,
                  });
                }}
              >
                {translate("MODULES.CHECKOUT.BTN_BENEFITS")}
              </Button>
            </SubscriptionDetails>
          </>
        )}
      </div>
    </div>
  );
};
export default Subscriptions;
