import ACTIONS from "../../../constants/actions";
import AssistanceMap from "../../assistanceV4/components/AssistanceFrame2/assistanceMap3";
import ClaraLayout from "../../../layouts/Clara/ClaraLayout";
import MapView from "./Map/MapView";
import PAGES from "../../../constants/pages";
import React from 'react';
import Text from "./../../../components/text/Text/Text";



const moduleRouter = [
  {
    path: PAGES.MODULES.MAP.maps.path,
    exact: true,
    breadCrumbs: {
      reset: true,
      add: false,
      label: <Text uuid={"MODULES.MAP.BREADCRUMS_MAPS"} />,
    },
    component: <MapView />,
    layout: ClaraLayout,
    propsLayout: {
      cantBack: true,
      startupMenu: true,
      userMenu: true,
      fullMenu: true,
      showAssistance: false,
      openAssistance: false,
      showBreadCrumbs: false,
      disableClosedAssistance: true,
      linkMenu: "LinkStakeHolderMap",
    },
    action: ACTIONS.VIEW_PROFILE,
  },

];
export default moduleRouter;
