import useLazyQuery from "src/hooks/custom/useLazyQuery";
export const GET_GROUP_COMPANY = `
query getGroupCompanyGeneralDetails($startupId: ID!, $groupCompanyId: ID!) {
  getGroupCompany(startupId: $startupId, groupCompanyId: $groupCompanyId) {
    id
    name
    companyType
    managedByClara
    registeredAddress {
      street
      city
      state
      country {
        code
        alpha3
        }
      zipCode
      }
    mailingAddress {
      street
      city
      state
      country {
        code
        alpha3
        }
      zipCode
      }
    locationCompanyRecordsAddress {
      street
      city
      state
      country {
        code
        alpha3
        }
      zipCode
      }
    locationAccountingRecordsAddress {
      street
      city
      state
      country {
        code
        alpha3
        }
      zipCode
      }
    }
}`

const useGetGroupCompany =(variables) => {
    const { manualQuery, loading, error, data, refetch } = useLazyQuery(GET_GROUP_COMPANY, variables);
    
    const query = async (variables) => {
        const { data } = await manualQuery({variables})
        return {
                groupCompany: data['getGroupCompany'],
        }
    }

    return {
        query,
        loading,
        error,
        data,
        refetch
    }
}
export default useGetGroupCompany