import _ from "lodash";
import { useMemo } from "react";
import { assign } from 'xstate';

const useActions = () => {
  
  return useMemo(() => ({
    addErrorCard: assign((context = {}, event) => {
      return {
        ...context,
        errorCard: true,
      };
    }),
    removeErrorCard: assign((context= {}, event) => {
      return {
        ...context,
        errorCard: false,
      };
    }),
  
  }), []);
}

export default useActions;