import { createTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import React, { useMemo } from "react";
import Scrollbar from "../../components/generic/Scrollbar/Scrollbar";
import { AssistanceTasksProvider } from "../../modules/assistanceV4/hooks/useAssistanceTasks";
import useBusinessRules from "../../modules/clara/hooks/useBusinessRules";
import claraTheme from "../../styles/themes/clara";
import "../../styles/themes/ClaraTheme.css";
import SessionManager from "../../v1/components/SessionManager/SessionManager";
import { GlobalModalProvider } from "./../../modules/generic/hooks/useModal";
import ManagerNotification from "./../../v1/components/ManagerNotification/ManagerNotification";
import classesModule from "./ClaraLayout.module.scss";
import BreadCrumbs from "./components/BreadCrumbs/BreadCrumbs";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import AssistanceEmpty from "../../modules/assistanceV4/components/AssistanceEmpty";
import useTranslate from "src/modules/generic/hooks/useTranslate";

const materialTheme = createTheme(claraTheme);

const ClaraLayout = ({
  children,
  scrollbar,
  showHeader = true,
  showBreadCrumbs = false,
  showFooter = true,
  startupMenu = false,
  userMenu = true,
  fullMenu = false,
  showAssistance: _showAssistance = false,
  openAssistance,
  disableClosedAssistance,
  assistance,
  assistanceProps={},
  assistanceViewer = null,
  firstMenu = false,
  ...props
}) => {
  const {translate} = useTranslate()
  const { isViewer } = useBusinessRules();

  let Component = assistance;
  const showAssistance = useMemo(() => {
    if (isViewer()) {
      if (assistanceViewer) {
        Component = assistanceViewer;
        return _showAssistance;
      } else {
        return false;
      }
    } else {
      return _showAssistance;
    }

    // return isViewer() ? false : _showAssistance;
  }, [_showAssistance, isViewer])


  const getContent = (className) => {
    const classBread = showBreadCrumbs ? classesModule.contentWithBreadCrumbs : null;
    return (
      <React.Fragment>
        {scrollbar === false ? (
          <div
            className={`${classesModule.content} ${className} ${classBread}`}
          >
            {children}
          </div>
        ) : (
          <Scrollbar
            className={`${classesModule.content} ${className}  ${classBread}`}
          >
            {children}
          </Scrollbar>
        )}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <SessionManager>
        <GlobalModalProvider >
          <ThemeProvider theme={materialTheme}>
            <ManagerNotification />
            <div className={`${classesModule.ClaraLayout}`}>
              {showHeader && (
                <Header
                  fullMenu={fullMenu}
                  startupMenu={startupMenu} 
                  userMenu={userMenu}
                  firstMenu={firstMenu}
                  {...props}
                />
              )}
              {showBreadCrumbs && (
                <div className={`${classesModule.contentWithAssistance4}`}>
                  <BreadCrumbs />
                </div>
              )}
              {showAssistance && assistance && (
                <React.Fragment>
                  <AssistanceTasksProvider>
                    {getContent(classesModule.contentWithAssistance4)}
                    <div className={`${classesModule.Assistance4}`}>
                      <Component {...assistanceProps}/>
                    </div>
                  </AssistanceTasksProvider>
                </React.Fragment>
              )}
              {!showAssistance && assistance && (
                <>
                  {getContent(classesModule.contentWithAssistance4)}
                  < div className={`${classesModule.Assistance4} ${classesModule.AssistanceView}`}>
                    <AssistanceEmpty icon="No-Messages" title={translate("ASSISTANCE_VIEWER_TEXT")}></AssistanceEmpty>
                  </div>
                </>
              )}
              {!showAssistance && !assistance && getContent()}
              {showFooter && <Footer />}
            </div>
          </ThemeProvider>
        </GlobalModalProvider>
      </SessionManager>
    </React.Fragment >
  );
};

export default ClaraLayout;